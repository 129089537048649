import React from 'react'
import notFoundImage from './img/not-found.svg';

const NotFoundBlock = ({ children }) => {
    return (
        <div style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexWrap: "nowrap",
            flexDirection: "column",
        }}>
            <img src={notFoundImage}  alt="not found"/>
            {children}
        </div>
    )
}

export default NotFoundBlock;