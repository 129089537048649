import React, { createContext, useContext } from "react";
import getParams from "../utils/getParams";
import Api from "../api";
import RootStore from "./RootStore";
import getEcwidInfo from "../utils/getEcwidInfo";
import Storage from "../utils/Storage";
import i18n from "i18next";
import Echo from 'laravel-echo';
import { defaultRedirect } from "../utils/history";
import getStoreInfo from "../utils/getStoreInfo";

function initLaravelEcho() {
    window.Pusher = require('pusher-js');
    window.Echo = new Echo({
        broadcaster: 'pusher',
        key: process.env.REACT_APP_PUSHER_APP_KEY,
        cluster: process.env.REACT_APP_APP_CLUSTER,
        authEndpoint: process.env.REACT_APP_BASE_URL_BROADCASTING_AUTH,
        wsHost: process.env.REACT_APP_BASE_URL_BROADCASTING_HOST,
        wsPort: process.env.REACT_APP_PUSHER_WS_PORT,
        wssPort: process.env.REACT_APP_PUSHER_WSS_PORT ?? process.env.REACT_APP_PUSHER_WS_PORT,
        forceTLS: false,
        encrypted: true,
        enabledTransports: ['ws', 'wss'],
        disableStats: false
    });
}

export const StoreContext = createContext("store");
const createStore = async () => {
    if (process.env.REACT_APP_BASE_URL_BROADCASTING_ENABLE === 'true') {
        initLaravelEcho();
    }

    const storagePayload = Storage.get('payload');
    const payload = getParams('payload') || storagePayload;

    if (payload) {
        Storage.set('payload', payload);
    }

    const EcwidApp = window.EcwidApp;

    EcwidApp.init({
        app_id: process.env.REACT_APP_APP_ID,
        autoloadedflag: true,
        autoheight: true,
    });

    const getRootStore = (storeName) => {
        return store[storeName];
    };

    const api = new Api({ payload, getRootStore });
    const store = new RootStore({ api, i18n });

    await getEcwidInfo(api, getRootStore);
    await getStoreInfo(api, getRootStore, store);

    defaultRedirect();

    api.setRequestCurrency();

    return ({ children }) => (
        <StoreContext.Provider value={store}>
            {children}
        </StoreContext.Provider>
    );
};

const useStore = () => useContext(StoreContext);

export { createStore, useStore };
