export default function UserGuideEn() {
    return (
        <div>
            <div className="user-guide-step">
                <h3>1. Getting started</h3>
                <p>
                    BRT is a fast and reliable Express Courier, specialised in the delivery of various shipments and
                    providing logistic support services for the handling and distribution of the products. With BRT you
                    can improve your customer satisfaction and forget about delayed or lost parcels. This app integrates
                    BRT service into your Online-store.
                </p>

                <p>
                    The app allows you to choose orders you want to deliver with BRT and track the parcels.
                </p>

                <p>
                    You need to have a BRT account to use the app. In case you do not have one, please register. A
                    detailed instruction for BRT account setup can be found in the following section.
                </p>
            </div>
            <div className="user-guide-step">
                <h3>2. BRT account setup</h3>
                <p>
                    To enable BRT shipping option, contact the service directly to create an account and receive further
                    instructions or you can contact your IOL's support to get more information.
                </p>
            </div>
            <div className="user-guide-step">
                <h3>3. BRT account integration</h3>
                <p>
                    First, you need to connect your BRT account to the Online-store. To do so, enter your ID, password
                    and other credentials for your existing BRT account into the appropriate text fields. Please check
                    carefully that all the credentials are correct.
                </p>
            </div>
            <div className="user-guide-step">
                <h3>4. Phone number</h3>
                <p>
                    All orders sent to BRT for fulfilment must include the phone number of customers.
                    So it is required to gather the phone number from customers on checkout.
                    Please enable the setting 'Require phone number at checkout' in the section "General Settings" ->
                    "Cart & Checkout" -> "Checkout settings" to do that.
                </p>
                <h3>5. Company name</h3>
                <p>
                    To ensure correct submission of orders to BRT, your store company name should have a length less
                    than 25 symbols. Please make sure the company name specified in the section Impostazioni -> Generale
                    has less than 25 symbols.
                </p>
                <h3>6. Setting up table rates</h3>
                <p>
                    The BRT delivery option will not be displayed for customers unless you change the Shipping & Pickup
                    settings.
                </p>
                <span className="text-big">To display BRT shipping option at checkout:</span>
                <ol>
                    <li>From your Online-store admin, go to Shipping & Pickup.</li>
                    <li>Click + Add Shipping.</li>
                    <li>Select ‘Other carriers’ and fill ‘BRT’ in the text field.</li>
                    <li>Click Set Up.</li>
                    <li>Click Set Up Custom rates.</li>
                </ol>
                <span className="text-big">Now, fill in all the required fields:</span>
                <ol>
                    <li>In the ‘Shipping name at checkout’ enter ‘BRT delivery’.</li>
                    <li>Click on the ‘Table based on’ drop-down menu and choose ‘Weight’.</li>
                    <li>Set the weight ranges to which the rates will apply. Click ‘Add row’ for extra rows in the
                        table.
                    </li>
                    <li>
                        Set the shipping rate you want to charge for each weight range. Go to the BRT site
                        <a href="https://www.bartolini.it/en/online-services/Estimates" target="_blank"
                           rel="noreferrer">
                            &nbsp;to calculate&nbsp;
                        </a>
                        the cost of a shipment for each weight range.
                    </li>
                    <li>Click Set Up Custom rates.</li>
                </ol>
                <p>Once a new shipping method is created, the customers will be able to select BRT as their delivery
                    option.</p>
                <p><b>Attention!</b> The app allows you to use BRT service for all the created orders regardless what
                    shipping method a customer chose. </p>
                <h3>7. Orders management</h3>
                <p>In this section, you can manage your orders and enable shipping with BRT or select other shipping and
                    delivery methods.</p>
                <span className="text-big">To enable BRT delivery, complete the following steps:</span>
                <ol>
                    <li>Change the order status to ‘Create request’ to start the process. You will get notifications
                        when the status is changed. After this step, the shipment barcode will be generated (PDF
                        format). Once this step is completed you can now proceed to a confirmation one.
                    </li>
                    <li>Change the order status to ‘Confirm request’. You will be notified when the status changes.</li>
                    <li>The final step is sending the request to BRT. To do so, change the status to ‘Send request’. The
                        BRT gets the request, and the orders will soon be picked up and delivered to customers. You will
                        be able to track the delivery status.
                    </li>
                </ol>
                <p>When a customer completes the checkout process, their order will appear in the ‘Orders’ section of
                    the app just as in ‘My Sales’. By default, the orders list is organized from newest to oldest. A
                    search bar allows you to find orders by order number, product title, SKU, or other details. The
                    orders can also be filtered by date, payment and fulfillment status etc.</p>
                <p>You can also filter your orders by BRT delivery status.</p>
                <h3>8. Package tracking</h3>
                <p>BRT status shows the progress of your orders shipment from a newly created request to a delivered
                    parcel. You can filter your orders by BRT status to control the process.</p>
                <span className="text-big">BRT status can change to:</span>
                <ol>
                    <li>Awaiting the ticket creation process / Pending</li>
                    <li>Ticket created</li>
                    <li>Ticket confirmed</li>
                    <li>The delivery data is transmitted to BRT</li>
                    <li>The package has been sent</li>
                    <li>Party</li>
                    <li>Arrived to filial</li>
                    <li>Delivering</li>
                    <li>Delivered</li>
                </ol>
                <p>When a customer completes the checkout process, their order will appear in the ‘Orders’ section of
                    the app just as in ‘My Sales’. By default, the orders list is organized from newest to oldest. A
                    search bar allows you to find orders by order number, product title, SKU, or other details. The
                    orders can also be filtered by date, payment and fulfillment status etc.</p>
                <p>You can also filter your orders by BRT delivery status.</p>
            </div>
        </div>
    );
}