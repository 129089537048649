import { React, useState, useEffect } from 'react';
import './styles/orderStatuses.sass';
import { RouteBlock } from '../../RouteBlock/RouteBlock';

const OrderStatuses = (props) => {
    const [bubbleWarningVisible, setBubbleWarningVisible] = useState('none');
    const [bubbleErrorVisible, setBubbleErrorVisible] = useState('none');
    const [bubbleLoadingVisible, setBubbleLoadingVisible] = useState('none');
    // eslint-disable-next-line no-unused-vars
    const [bubbleSuccessVisible, setBubbleSuccessVisible] = useState('none');
    const [bubbleTrackingVisible, setBubbleTrackingVisible] = useState('none');
    const [bubbleInfoVisible, setBubbleInfoVisible] = useState('none');

    const [currentStatus, setCurrentStatus] = useState({
        'status': props?.statusSetting?.status,
        'bubbleTitle': props?.statusSetting?.bubbleTitle,
        'bubbleMessage': props?.statusSetting?.bubbleMessage,
        'title': props?.statusSetting?.title
    });

    useEffect(() => {
        setCurrentStatus({
            'status': props?.statusSetting?.status,
            'bubbleTitle': props?.statusSetting?.bubbleTitle,
            'bubbleMessage': props?.statusSetting?.bubbleMessage,
            'title': props?.statusSetting?.title
        });
    }, [props?.statusSetting]);

    return (
        <div style={{
            paddingLeft: '12px',
            display: 'flex',
            alignItems: 'flex-start'
        }}>
            {currentStatus.status === 'unsuccessfully' ?
                <div className="canonical-status text-default canonical-status--warning canonical-status--has-icon canonical-status--prepend-icon">
                    <div
                        className="canonical-status__icon"
                        onMouseEnter={() => {
                            setBubbleErrorVisible('block');
                        }}
                        onMouseLeave={() => {
                            setBubbleErrorVisible('none');
                        }}
                    >
                        <span style={{ cursor: 'pointer' }}>
                            <svg style={{
                                width: '24px',
                                height: '24px',
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                            }} xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none" viewBox="0 0 18 18"><circle cx="9" cy="9" r="5.5" stroke="#F43C36" fill="none" /><path fill="#F43C36" d="M8.999 5.503a.564.564 0 0 0-.25.054.643.643 0 0 0-.211.163.761.761 0 0 0-.14.246.848.848 0 0 0-.048.289v3.292c0 .2.068.391.19.533.122.14.287.22.459.22.172 0 .337-.08.459-.22a.818.818 0 0 0 .19-.533V6.255a.8.8 0 0 0-.034-.298.718.718 0 0 0-.14-.253.598.598 0 0 0-.218-.162.524.524 0 0 0-.257-.04ZM9 12.5a.75.75 0 1 0-.75-.75.761.761 0 0 0 .75.75Z" /></svg>
                        </span>
                    </div>
                    <div
                        onMouseEnter={() => {
                            setBubbleErrorVisible('block');
                        }}
                        onMouseLeave={() => {
                            setBubbleErrorVisible('none');
                        }}
                        className="bubble bubble--error bubble--right cm-tooltip-absolute" style={{
                            display: bubbleErrorVisible,
                        }}>
                        <div className="bubble__container">
                            <div className="bubble__title">{currentStatus?.bubbleTitle}</div>
                            <div className="bubble__text">{currentStatus?.bubbleMessage}</div>
                        </div>
                    </div>
                </div>
                : <div></div>}
            {currentStatus.status === 'warning' ?
                <div className="canonical-status text-default canonical-status--warning canonical-status--has-icon canonical-status--prepend-icon">
                    <div
                        className="canonical-status__icon"
                        onMouseEnter={() => {
                            setBubbleWarningVisible('block');
                        }}
                        onMouseLeave={() => {
                            setBubbleWarningVisible('none');
                        }}
                    >
                        <span style={{ cursor: 'pointer' }}>
                            <svg style={{
                                width: '18px',
                                height: '18px',
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                            }} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M13 16c0 .5523-.4477 1-1 1s-1-.4477-1-1v-4c0-.5523.4477-1 1-1s1 .4477 1 1v4ZM12 9.5c.6904 0 1.25-.5596 1.25-1.25S12.6904 7 12 7s-1.25.5596-1.25 1.25S11.3096 9.5 12 9.5Z" /><path fillRule="evenodd" clipRule="evenodd" d="M12 21c4.9706 0 9-4.0294 9-9s-4.0294-9-9-9-9 4.0294-9 9 4.0294 9 9 9Zm0 2c6.0751 0 11-4.9249 11-11S18.0751 1 12 1 1 5.9249 1 12s4.9249 11 11 11Z" /></svg>
                        </span>
                    </div>
                    <div className="bubble bubble--warning bubble--right cm-tooltip-absolute" style={{
                        display: bubbleWarningVisible,
                    }}>
                        <div
                            onMouseEnter={() => {
                                setBubbleWarningVisible('block');
                            }}
                            onMouseLeave={() => {
                                setBubbleWarningVisible('none');
                            }}
                            className="bubble__container">
                            <div className="bubble__title">{currentStatus?.bubbleTitle}</div>
                            <div className="bubble__text">{currentStatus?.bubbleMessage}</div>
                        </div>
                    </div>
                </div>
                : <div></div>}

            {currentStatus.status === 'successfully' ?
                <div className="feature-section__status">
                    <div
                        className="canonical-status text-default canonical-status--success canonical-status--has-icon canonical-status--prepend-icon">
                        <div className="canonical-status__text">
                            {currentStatus?.title ?? 'successfully'}
                        </div>
                        <div className="canonical-status__icon">
                            <span>
                                <svg width="18" height="18" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd"
                                        d="M16.6508 2.7408c.4193.3594.4679.9907.1085 1.41l-9 10.5c-.3791.4423-1.0545.4682-1.4664.0563l-5-5c-.3905-.3905-.3905-1.0237 0-1.4142.3905-.3905 1.0237-.3905 1.4142 0l4.2364 4.2364 8.2972-9.68c.3595-.4194.9908-.468 1.4101-.1085z"></path></svg></span>
                        </div>
                    </div>
                </div>
                : <div></div>}
            {currentStatus.status === 'inProgress' ?
                <div
                    className="
                        canonical-status 
                        text-default 
                        canonical-status--loading 
                        canonical-status--warning 
                        canonical-status--has-icon 
                        canonical-status--prepend-icon
                        cm-canonical-status"

                    onMouseEnter={() => {
                        setBubbleLoadingVisible('block');
                    }}
                    onMouseLeave={() => {
                        setBubbleLoadingVisible('none');
                    }}
                    style={{ cursor: 'pointer' }}
                >
                    <div className="canonical-status__text">{currentStatus?.title ?? 'Loading'}</div>
                    <div className="canonical-status__icon">
                        <svg viewBox="0 0 28 28" xmlns="http://www.w3.org/2000/svg" width="28" height="28">
                            <path d="M14,27C6.83,27,1,21.17,1,14c0-1.56,0.27-3.08,0.81-4.52C2.1,8.7,2.96,8.31,3.74,8.59c0.78,0.29,1.17,1.15,0.88,1.93 C4.21,11.63,4,12.8,4,14c0,5.51,4.49,10,10,10c5.51,0,10-4.49,10-10c0-5.51-4.49-10-10-10c-0.83,0-1.5-0.67-1.5-1.5S13.17,1,14,1 c7.17,0,13,5.83,13,13C27,21.17,21.17,27,14,27z"></path>
                        </svg>
                    </div>
                    <div className="bubble bubble--warning bubble--right cm-tooltip-absolute" style={{
                        display: bubbleLoadingVisible,
                    }}>
                        <div
                            onMouseEnter={() => {
                                setBubbleLoadingVisible('block');
                            }}
                            onMouseLeave={() => {
                                setBubbleLoadingVisible('none');
                            }}
                            className="bubble__container">
                            <div className="bubble__title">{currentStatus?.bubbleTitle}</div>
                            <div className="bubble__text">{currentStatus?.bubbleMessage}</div>
                        </div>
                    </div>
                </div>
                : <div></div>}
            {currentStatus.status === 'tracking' ?
                <div className="canonical-status text-default canonical-status--success canonical-status--has-icon canonical-status--prepend-icon">
                    <div
                        className="canonical-status__icon"
                        onMouseEnter={() => {
                            setBubbleTrackingVisible('block');
                        }}
                        onMouseLeave={() => {
                            setBubbleTrackingVisible('none');
                        }}
                    >
                        <span style={{ cursor: 'pointer' }}>
                            <svg
                                style={{
                                    width: '20px',
                                    height: '20px',
                                    weight: '900',
                                    position: 'absolute',
                                    color: '#7D8D9E',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                }}
                                xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="m13.262 12.51.879-.981a1 1 0 1 1 1.49 1.335l-.877.978-2.77 3.13a.5.5 0 0 1-.714.035l-2.252-2.074a1 1 0 0 1 1.355-1.471L11.5 14.5l1.762-1.99z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M7.1 5a5.002 5.002 0 0 1 9.8 0H21a1 1 0 0 1 1 1v10a7 7 0 0 1-7 7H9a7 7 0 0 1-7-7V6a1 1 0 0 1 1-1h4.1zm2.07 0a3.001 3.001 0 0 1 5.66 0H9.17zM7 7v1a1 1 0 0 0 2 0V7h6v1a1 1 0 1 0 2 0V7h3v9a5 5 0 0 1-5 5H9a5 5 0 0 1-5-5V7h3z" fill="currentColor" /></svg>
                        </span>
                    </div>
                    <div
                        onMouseEnter={() => {
                            setBubbleTrackingVisible('block');
                        }}
                        onMouseLeave={() => {
                            setBubbleTrackingVisible('none');
                        }}
                        className="bubble bubble--info bubble--right cm-tooltip-absolute" style={{
                            display: bubbleTrackingVisible,
                        }}>
                        <div className="bubble__container">
                            <div className="bubble__title">{currentStatus?.bubbleTitle}</div>
                            <div className="bubble__text">{currentStatus?.bubbleMessage}</div>
                        </div>
                    </div>
                </div>
                : <div></div>}

            {currentStatus.status === 'trackingLine' ? //trackingLine
                <div className="canonical-status text-default canonical-status--info canonical-status--has-icon canonical-status--prepend-icon" style={{ position: 'absolute' }}>
                    <div
                        className="canonical-status__icon"
                        onMouseEnter={() => {
                            setBubbleTrackingVisible('block');
                        }}
                        onMouseLeave={() => {
                            setBubbleTrackingVisible('none');
                        }}
                    >
                        <span style={{ cursor: 'pointer' }}>
                            <svg
                                style={{
                                    width: '18px',
                                    height: '18px',
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                }}
                                xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M2 3a1 1 0 0 0-1 1v14a1 1 0 0 0 1 1h1.17a3.001 3.001 0 0 0 5.66 0h6.34a3.001 3.001 0 0 0 5.66 0H22a1 1 0 0 0 1-1v-4a3 3 0 0 0-3-3h-.22l-1.62-6.485A2 2 0 0 0 16.22 3H2zm18.83 14H21v-3a1 1 0 0 0-1-1h-7a1 1 0 0 1-1-1V5H3v12h.17a3.001 3.001 0 0 1 5.66 0h6.34a3.001 3.001 0 0 1 5.66 0zm-3.11-6-1.5-6H14v6h3.72zM6 17a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm11 1a1 1 0 1 1 2 0 1 1 0 0 1-2 0z" fill="currentColor" /></svg>
                        </span>
                    </div>
                    <div
                        onMouseEnter={() => {
                            setBubbleTrackingVisible('block');
                        }}
                        onMouseLeave={() => {
                            setBubbleTrackingVisible('none');
                        }}
                        className="bubble bubble--info bubble--right cm-tooltip-absolute" style={{
                            display: bubbleTrackingVisible,
                        }}>
                        <div className="bubble__container">
                            <div className="bubble__title">{currentStatus?.bubbleTitle}</div>
                            <div className="bubble__text">{currentStatus?.bubbleMessage}</div>
                            <RouteBlock
                                trackingEvents={props?.trackingEvents}
                            />
                        </div>
                    </div>
                </div>
                : <div></div>}

            {currentStatus.status === 'info' ?
                <div className="canonical-status text-default canonical-status canonical-status--has-icon canonical-status--prepend-icon">
                    <div
                        className="canonical-status__icon"
                        onMouseEnter={() => {
                            setBubbleInfoVisible('block');
                        }}
                        onMouseLeave={() => {
                            setBubbleInfoVisible('none');
                        }}
                    >
                        <span style={{ cursor: 'pointer' }}>
                            <svg style={{
                                width: '18px',
                                height: '18px',
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                color: '#7D8D9E',
                                transform: 'translate(-50%, -50%)',
                            }} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M13 16c0 .5523-.4477 1-1 1s-1-.4477-1-1v-4c0-.5523.4477-1 1-1s1 .4477 1 1v4ZM12 9.5c.6904 0 1.25-.5596 1.25-1.25S12.6904 7 12 7s-1.25.5596-1.25 1.25S11.3096 9.5 12 9.5Z" /><path fillRule="evenodd" clipRule="evenodd" d="M12 21c4.9706 0 9-4.0294 9-9s-4.0294-9-9-9-9 4.0294-9 9 4.0294 9 9 9Zm0 2c6.0751 0 11-4.9249 11-11S18.0751 1 12 1 1 5.9249 1 12s4.9249 11 11 11Z" /></svg>
                        </span>
                    </div>
                    <div className="bubble bubble--info bubble--right cm-tooltip-absolute" style={{
                        display: bubbleInfoVisible,
                    }}>
                        <div
                            onMouseEnter={() => {
                                setBubbleInfoVisible('block');
                            }}
                            onMouseLeave={() => {
                                setBubbleInfoVisible('none');
                            }}
                            className="bubble__container">
                            <div className="bubble__title">{currentStatus?.bubbleTitle}</div>
                            <div className="bubble__text">{currentStatus?.bubbleMessage}</div>
                        </div>
                    </div>
                </div>
                : <div></div>}
        </div>
    );
}
export default OrderStatuses;