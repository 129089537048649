import {makeAutoObservable} from "mobx";
import Storage from "../utils/Storage";

//Переделать на функциональный компонент.
class ModalStore {
    constructor() {
        this.isOpen = Storage.get('modal-open') === 'true';
        makeAutoObservable(this);
    }

    openModal() {
        this.isOpen = true;
        Storage.set('modal-open', true);
    }

    closeModal() {
        this.isOpen = false;
        Storage.set('modal-open', false);
    }
}

export default ModalStore;
