import React from 'react';
import {t} from "i18next";

const Pagination = (props) => {
    return (
        <div className="pagination mt-5">
            <button className="btn btn-default btn-medium pagination__nav pagination__nav--prev" onClick={props?.prevClick} disabled={props?.prevClickDisabled}>
                <span className="svg-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 26" focusable="false">
                        <path fill="#010101" d="M18.5 3.85l-8.9 9.02 8.9 9.27c.66.65.66 1.71 0 2.36-.67.65-1.74.65-2.4 0L6 14.06c-.33-.33-.5-.76-.5-1.18 0-.43.17-.86.5-1.18L16.1 1.49c.66-.65 1.74-.65 2.41 0 .66.65.66 1.71-.01 2.36z"/>
                    </svg>
                </span>
            </button>
            <span className="pagination__label">
                {t('dashboard.orders_listing.page')}:
                <span className="pagination__current">
                    <input type="number" className="form-control input-medium" value={props?.page} onChange={props?.onChange} />
                    <span className="form-control input-medium input-size"> {props?.page} </span>
                </span>
                {t('dashboard.orders_listing.of_page')} <span className="pagination__total"> {props?.total ?? 1} </span>
            </span>
            <button className="btn btn-default btn-medium pagination__nav pagination__nav--next" onClick={props?.nextClick} disabled={props?.nextClickDisabled}>
                <span className="svg-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 26" focusable="false">
                        <path fill="#010101" d="M7.5 22.15l8.9-9.02-8.9-9.28c-.66-.65-.66-1.71 0-2.36.67-.65 1.74-.65 2.4 0L20 11.94c.33.33.5.76.5 1.18 0 .43-.17.86-.5 1.18L9.9 24.51c-.66.65-1.74.65-2.41 0-.66-.65-.66-1.71.01-2.36z"/>
                    </svg>
                </span>
            </button>
        </div>
    )
}

export default Pagination;