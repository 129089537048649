import './App.css';
import { useEffect } from 'react';
import links from "./constants/links";
import { Switch, Route } from "react-router";
import NotFoundPage from "./views/NotFoundPage/NotFoundPage";
import DashboardPage from "./views/DashboardPage/DashboardPage";
import SomethingWentWrongPage from "./views/SomethingWentWrongPage/SomethingWentWrongPage";

const App = () => {
    useEffect(() => {
        const script = document.createElement('script');

        script.src = "https://d35z3p2poghz10.cloudfront.net/ecwid-sdk/css/1.3.13/ecwid-app-ui.min.js";
        script.async = true;

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        }

    });

    const { pages: {
        dashboard, somethingWentWrong, notFound,
    } } = links;

    return (
        <Switch>
            <Route path={dashboard.path} component={DashboardPage} />
            <Route path={somethingWentWrong.path} component={SomethingWentWrongPage} />
            <Route path={notFound.path} component={NotFoundPage} />
        </Switch>
    )
}

export default App;
