import React, { useState } from 'react';
import './styles/acard.sass';
import {t} from "i18next";

const Unsaved = (props) => {
    const [saveButton, setSaveButton] = useState();
    return (
        <div className="a-card a-card--normal cm-info-block cm-info-block--bg-info">
            <div className="a-card__paddings">
                <div className="cm-info-block__content">
                    <p className="cm-info-block__content__title">{t('dashboard.changed_forms.changed')}</p>
                    <div className="cm-info-block__content__buttons">
                        <button className="btn btn-default btn-medium cm-info-block__content__button" onClick={props.onClickCancel}>{t('dashboard.changed_forms.cancel')}</button>
                        <button className={"btn btn-primary btn-medium cm-info-block__content__button " + saveButton} onClick={() => {
                            setSaveButton('btn-loading');
                            props.onClickSave();
                        }}>{t('dashboard.changed_forms.save')}</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

const Saved = () => {
    return (
        <div className="a-card a-card--success cm-info-block">
            <div className="a-card__paddings">
                <div className="cm-save-block__content">
                    <p className="cm-save-block__content__title">{t('dashboard.changed_forms.success')}</p>
                </div>
            </div>
        </div>
    )
}

const Warning = () => {
    return (
        <div className="a-card a-card--warning cm-info-block">
            <div className="a-card__paddings">
                <div className="cm-save-block__content">
                    <p className="cm-save-block__content__title">{t('dashboard.changed_forms.error')}</p>
                </div>
            </div>
        </div>
    )
}

const Error = (props) => {
    return (
        <div className="a-card a-card--error cm-info-block">
            <div className="a-card__paddings">
                <div className="cm-info-block__content">
                    <p className="cm-info-block__content__title">{t('dashboard.changed_forms.error')}</p>
                    <div className="cm-info-block__content__buttons">
                        <button className="btn btn-default btn-medium cm-info-block__content__button" onClick={props.onClickCancel}>{t('dashboard.changed_forms.reload')}</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

const bottomBlock = (status, props) => {
    switch (status) {
        case 'unsaved':
            return <Unsaved onClickSave={props?.onClickSave} onClickCancel={props.onClickCancel} />
        case 'saved':
            return <Saved />
        case 'warning':
            return <Warning />
        case 'error':
            return <Error onClickCancel={props.onClickCancel} />
        default:
            return;
    }
}

const Acard = ({ children, ...props }) => {
    return (
        <div className="a-card a-card--normal">
            <div className="a-card__paddings">
                {children}
            </div>
            {bottomBlock(props?.cardStatus, props)}
        </div>
    )
}

export default Acard;