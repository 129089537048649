import React from "react";
// import NotFound from "../../components/NotFound/NotFound";
import './styles/notFoundPage.sass';

function NotFoundPage() {
    return (
        <div className="notFoundPageWrapper">
            <h1>NotFoundPage</h1>
            {/* <NotFound /> */}
        </div>
    )
}

export default NotFoundPage;
