import { React, useState, useEffect, useMemo } from 'react'
import { useStore } from '../../../store';
import {
    Form, FormTitle, FormContent, Fieldsets, FieldsetInput,
} from '../Form/Form';
import { useInput } from '../../../hooks/useForm';
import './styles/orderCard.sass';
import classNames from 'classnames';
import OrderStatuses from "../OrderStatuses/OrderStatuses";
import Storage from "../../../utils/Storage";
import { t } from "i18next";

const OrderCard = (props) => {
    const weight = useInput(props.changedData?.weight || props.weight, props.weight, { isEmpty: true, isFloat: true });
    const width = useInput(props.changedData?.width || props.width, props.width, { isEmpty: true, isFloat: true });
    const height = useInput(props.changedData?.height || props.height, props.height, { isEmpty: true, isFloat: true });
    const length = useInput(props.changedData?.length || props.length, props.length, { isEmpty: true, isFloat: true });

    const [bubbleConfig, setBubbleConfig] = useState(props?.bubbleConfig);
    const [oldBubbleConfig, setOldBubbleConfig] = useState(props?.bubbleConfig);

    useEffect(() => {
        setOldBubbleConfig(props?.bubbleConfig);
        setBubbleConfig(props?.bubbleConfig);
    }, [props?.bubbleConfig]);

    // const [id, setId] = useState(props.id);
    const [statusVisible, setStatusVisible] = useState(true);
    // eslint-disable-next-line no-unused-vars
    const [inputDisable] = useState(props?.inputDisable ?? false);
    // eslint-disable-next-line no-unused-vars
    const [hasChanged, setHasChanged] = useState(false);
    const [barcodeLoading, setBarcodeLoading] = useState(false);

    const [orderStatuses] = useState([
        {
            key: 1,
            statusLabel: t('dashboard.orders_listing.order_card.brt_statuses.AWAITING_CREATION'),
            menuItemLabel: t('dashboard.orders_listing.order_card.brt_statuses.AWAITING_CREATION'),
            type: 'AWAITING_CREATION',
            canSelectTypes: [
                { type: 'AWAITING_CREATION', active: true },
                { type: 'SHIPMENT_CREATED', active: true },
                { type: 'SHIPMENT_CONFIRMED', active: false },
                { type: 'SHIPMENT_SHIPPED', active: false },
            ],
            handle: function () {
                setCurrentStatus('AWAITING_CREATION');
                setCurrentStatusLabel(this.menuItemLabel);
                setStatusVisible(true);
            }
        },
        {
            key: 2,
            statusLabel: t('dashboard.orders_listing.order_card.brt_statuses.create_request'),
            menuItemLabel: t('dashboard.orders_listing.order_card.brt_statuses.SHIPMENT_CREATED'),
            type: 'SHIPMENT_CREATED',
            canSelectTypes: [
                { type: 'SHIPMENT_CREATED', active: true },
                { type: 'SHIPMENT_CONFIRMED', active: true },
                { type: 'DELETED', active: false },
                { type: 'SHIPMENT_SHIPPED', active: false },
            ],
            handle: function () {
                setCurrentStatus('SHIPMENT_CREATED');
                setCurrentStatusLabel(this.menuItemLabel);
                setStatusVisible(true);
            }
        },
        {
            key: 3,
            statusLabel: t('dashboard.orders_listing.order_card.brt_statuses.confirm_request'),
            menuItemLabel: t('dashboard.orders_listing.order_card.brt_statuses.SHIPMENT_CONFIRMED'),
            type: 'SHIPMENT_CONFIRMED',
            canSelectTypes: [
                { type: 'SHIPMENT_CONFIRMED', active: true },
                { type: 'SHIPMENT_SHIPPED', active: true },
                { type: 'DELETED', active: true },
            ],
            handle: function () {
                setCurrentStatus('SHIPMENT_CONFIRMED');
                setCurrentStatusLabel(this.menuItemLabel);
                setStatusVisible(true);
            }
        },
        {
            key: 4,
            statusLabel: props.brtTrackingStatus ?? t('requestSent'),
            menuItemLabel: props.brtTrackingStatus ?? t('dashboard.orders_listing.order_card.brt_statuses.AWAITING_TRACKING_STATUS'),
            type: 'SHIPMENT_SHIPPED',
            canSelectTypes: [],
            handle: function () {
                setCurrentStatus('SHIPMENT_SHIPPED');
                setCurrentStatusLabel(props.brtTrackingStatus ?? t('requestSent'));
                setStatusVisible(true);
            }
        },
        {
            key: 5,
            statusLabel: t('dashboard.orders_listing.order_card.brt_statuses.delete_ticket'),
            menuItemLabel: t('dashboard.orders_listing.order_card.brt_statuses.ticket_deleted'),
            type: 'DELETED',
            canSelectTypes: [],
            handle: function () {
                setCurrentStatus('DELETED');
                setCurrentStatusLabel(this.menuItemLabel);
                setStatusVisible(true);
            }
        }
    ]);

    const [currentStatus, setCurrentStatus] = useState(props.changedData?.status || props.currentStatus);
    const [currentStatusLabel, setCurrentStatusLabel] = useState(orderStatuses.find(i => i.type === currentStatus).menuItemLabel);
    const [currentStatusType] = useState(orderStatuses.find(i => i.type === currentStatus));

    const {
        ecwidStore: { storeInfo, getBarcode },
    } = useStore();

    const { onChangeAttributes } = props;
    useEffect(() => {
        if (height.changed || length.changed || weight.changed || width.changed || currentStatus) {
            const defaultAttributes = {
                weight: props.weight,
                width: props.width,
                height: props.height,
                length: props.length,
                status: props.currentStatus
            };

            const changedAttributes = {
                weight: weight.value,
                width: width.value,
                height: height.value,
                length: length.value,
                status: currentStatus
            };

            const changedValuesOfAttributes = {};
            Object.keys(defaultAttributes).forEach(key => {
                /* eslint eqeqeq: 0 */
                if (defaultAttributes[key] != changedAttributes[key]) {
                    changedValuesOfAttributes[key] = changedAttributes[key];
                    setBubbleConfig({
                        'status': 'warning',
                        'bubbleTitle': t('dashboard.orders_listing.order_card.bubbles.warning.order_card_has_been_changed'),
                        'bubbleMessage': t('dashboard.orders_listing.order_card.bubbles.warning.please_click_save'),
                    });
                } else if (changedValuesOfAttributes.hasOwnProperty(key)) {
                    delete changedValuesOfAttributes[key];
                }
            });
            setHasChanged(Object.keys(changedValuesOfAttributes).length > 0);
            if (Object.keys(changedValuesOfAttributes).length === 0) {
                setBubbleConfig(oldBubbleConfig);
            }
            onChangeAttributes(changedValuesOfAttributes, props.id);
        }
    }, [
        height.value, length.value, weight.value, width.value, currentStatus, onChangeAttributes,
        height.changed, length.changed, weight.changed, width.changed,
        props.weight, props.width, props.height, props.length, props.id, props.currentStatus, oldBubbleConfig
    ]);

    const downloadBarcode = (orderId) => {
        setBarcodeLoading(true);
        getBarcode(orderId).then((response) => {
            const file = window.URL.createObjectURL(response);
            const a = document.createElement("a");
            a.href = file;
            a.download = `${orderId}.pdf`;
            document.body.appendChild(a);
            a.click();
        }).finally(() => {
            setBarcodeLoading(false);
        });
    }

    const isCheckboxDisabled = useMemo(() => {
        return !(currentStatus === "SHIPMENT_SHIPPED");
    }, [currentStatus]);

    const checkboxStyle = isCheckboxDisabled ? { backgroundColor: '#ffffff' } : {};

    return (
        <div className="list-element list-element--compact list-element--inline-mode pt-4">
            <div className="list-element__toggle">
                <div className="custom-checkbox custom-checkbox--disabled" style={{ paddingLeft:'0' }}>
                    <label>
                        <input type="checkbox" defaultValue="on" id={props?.id} tabIndex="0"
                               className="custom-checkbox__input" onChange={props?.onChangeCheckbox}
                               checked={props?.checked} disabled={isCheckboxDisabled}/>
                        <label htmlFor={props?.id} className="custom-checkbox__label" style={checkboxStyle}/>
                    </label>
                </div>
            </div>
            <div className="list-element__content position-relative">
                {props.stream && <button className={classNames(
                    "btn btn-default btn-medium position-absolute barcode-button",
                    { 'btn-loading': barcodeLoading }
                )} onClick={() => downloadBarcode(props.ecwidOrderId)}
                >{ t('dashboard.orders_listing.order_card.download_barcode')}</button>}
                <div className="list-element__info">
                    <div className="list-element__header">
                        <div className="list-element__main-info">
                            <div className="list-element__title">
                                <h4>{props.ecwidOrderId}</h4>
                            </div>
                            <div className="list-element__description">
                                <span className="muted">
                                    {props.orderDate}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="list-element__data-row">
                        <div className="feature-section__status d-flex">
                            <div className="canonical-status text-default canonical-status--has-action"
                                 style={{ display: 'flex' }}>
                                <div
                                    className="canonical-status__text">{t('dashboard.orders_listing.order_card.status')}:
                                </div>
                                <div className="canonical-status__icon" />
                                <div className="canonical-status__action" style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                }}>
                                    <div className="dropdown-menu" style={{
                                        dislay: 'flex',
                                        alignItems: 'center'
                                    }}>
                                        <div className="dropdown-menu__link">
                                            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                            <a className="iconable-link" onClick={() => setStatusVisible(!statusVisible)}>
                                                <span className="iconable-link__text">{currentStatusLabel}</span>&zwj;
                                                {currentStatus !== 'SHIPMENT_SHIPPED' ?
                                                    <span className="iconable-link__icon">
                                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 28"
                                                             focusable="false">
                                                            <path
                                                                d="M3.3 9.5l5.6 5.1 6-5.1c.8-.7 1.9-.7 2.6 0 .8.7.8 1.8 0 2.5l-7.2 6.4c-.5.4-1 .6-1.4.6s-1-.2-1.3-.5L.7 12.1c-.8-.7-.8-1.8 0-2.5.6-.8 1.9-.8 2.6-.1z"/>
                                                        </svg>
                                                    </span>
                                                    : <span></span>
                                                }
                                            </a>
                                        </div>
                                        <div
                                            className={"list-dropdown list-dropdown-medium " + classNames({ 'd-none': statusVisible })}
                                            aria-hidden="true"
                                            onMouseLeave={(e) => {
                                                setStatusVisible(true);
                                            }}
                                        >
                                            <ul>
                                                {/* eslint-disable-next-line array-callback-return */}
                                                {orderStatuses?.map((status) => {
                                                    if (currentStatusType.canSelectTypes.filter(s => s.type !== currentStatus).some(i => i.type === status.type)) {
                                                        let isDisabled = !currentStatusType.canSelectTypes.find(i => i.type === status.type)?.active;

                                                        if (!weight?.value || !height?.value || !length?.value || !width?.value || bubbleConfig?.status === 'inProgress') {
                                                            isDisabled = true;
                                                        }

                                                        return <li
                                                            key={status.key}
                                                            style={{
                                                                cursor: isDisabled ? 'not-allowed' : 'auto'
                                                            }}>
                                                            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                                            <a className="gwt-Anchor"
                                                               disabled={isDisabled}
                                                               onClick={() => status.handle()}>
                                                                {status.statusLabel}
                                                            </a>
                                                        </li>
                                                    }
                                                })}
                                            </ul>
                                        </div>
                                        <select className="dropdown-menu__list text-default" aria-hidden="true"
                                                style={{ display: "none" }}/>
                                    </div>
                                </div>
                            </div>
                            <OrderStatuses
                                statusSetting={bubbleConfig}
                                trackingEvents={props?.trackingEvents}
                            />
                        </div>
                        <Form>
                            <FormTitle>
                                <div className="labeled-icon labeled-icon--prepend-icon">
                                    <span className="labeled-icon__icon svg-icon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18"
                                             viewBox="0 0 18 18" fill="none">
                                            <path
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                                d="M8.743.571a.5.5 0 0 1 .514 0l7.5 4.5A.5.5 0 0 1 17 5.5v7a.5.5 0 0 1-.223.416l-7.5 5a.5.5 0 0 1-.534.013l-7.5-4.5A.5.5 0 0 1 1 13V5.5a.5.5 0 0 1 .243-.429l7.5-4.5zM6.222 3.25l6.528 3.917L15.528 5.5 9 1.583 6.222 3.25zM16 6.383l-6.5 3.9v6.283l6.5-4.334V6.383zM8.5 16.617v-6.334L2 6.383v6.334l6.5 3.9zM2.472 5.5 9 9.417l2.778-1.667L5.25 3.833 2.472 5.5z"
                                                fill="currentColor" />
                                        </svg>
                                    </span>
                                    <span className="labeled-icon__label"><span
                                        className="gwt-InlineLabel muted">{props?.shippingMethod ?? 'Is unknown'}</span></span>
                                </div>
                            </FormTitle>
                            <FormContent>
                                <Fieldsets>
                                    <FieldsetInput
                                        title={t('dashboard.orders_listing.order_card.weight')}
                                        placeholder={t('dashboard.orders_listing.order_card.input_placeholder')}
                                        attribute={weight}
                                        value={weight.value}
                                        name={props?.ecwidOrderId + "-weight"}
                                        onChange={(e) => weight.onChange(e)}
                                        statusClass={weight.valid}
                                        invalidMessage={weight.message}
                                        disabled={currentStatus !== 'AWAITING_CREATION' || bubbleConfig?.status === 'inProgress'}
                                    />
                                    <FieldsetInput
                                        title={t('dashboard.orders_listing.order_card.length')}
                                        placeholder={t('dashboard.orders_listing.order_card.input_placeholder')}
                                        attribute={length}
                                        value={length.value}
                                        name={props?.ecwidOrderId + "-length"}
                                        onChange={(e) => length.onChange(e)}
                                        statusClass={length.valid}
                                        invalidMessage={length.message}
                                        disabled={currentStatus !== 'AWAITING_CREATION' || bubbleConfig?.status === 'inProgress'}
                                    />
                                    <FieldsetInput
                                        title={t('dashboard.orders_listing.order_card.width')}
                                        placeholder={t('dashboard.orders_listing.order_card.input_placeholder')}
                                        attribute={width}
                                        value={width.value}
                                        name={props?.ecwidOrderId + "-width"}
                                        onChange={(e) => width.onChange(e)}
                                        statusClass={width.valid}
                                        invalidMessage={width.message}
                                        disabled={currentStatus !== 'AWAITING_CREATION' || bubbleConfig?.status === 'inProgress'}
                                    />
                                    <FieldsetInput
                                        title={t('dashboard.orders_listing.order_card.height')}
                                        placeholder={t('dashboard.orders_listing.order_card.input_placeholder')}
                                        attribute={height}
                                        value={height.value}
                                        name={props?.ecwidOrderId + "-height"}
                                        onChange={(e) => height.onChange(e)}
                                        statusClass={height.valid}
                                        invalidMessage={height.message}
                                        disabled={currentStatus !== 'AWAITING_CREATION' || bubbleConfig?.status === 'inProgress'}
                                    />
                                </Fieldsets>
                                <div className="cm-order-card-annotation">
                                    <p className="cm-order-card-annotation__item">
                                        <span className="muted">
                                            {t('dashboard.orders_listing.order_card.annotations.items_count')}:&nbsp;
                                        </span>
                                        <b>
                                            {props?.productCount ?? 0}
                                        </b>
                                    </p>
                                    <p className="cm-order-card-annotation__item">
                                        <span className="muted">
                                            {t('dashboard.orders_listing.order_card.annotations.items')}:&nbsp;
                                        </span>
                                        <b>
                                            {Storage.get('currencyPrefix')}{props?.totalPrice ?? 0}{Storage.get('currencySuffix')}
                                        </b>
                                    </p>
                                    <p className="cm-order-card-annotation__item">
                                        <span className="muted">
                                            {t('dashboard.orders_listing.order_card.annotations.shipping')}:&nbsp;
                                        </span>
                                        <b>
                                            {Storage.get('currencyPrefix')}{props?.shippingRate ?? 0}{Storage.get('currencySuffix')}
                                        </b>
                                    </p>
                                </div>
                            </FormContent>
                        </Form>
                    </div>
                </div>
            </div>
            <div className="list-element__move-forward-arrow disabled" onClick={() => {
                window.open('https://my.ecwid.com/store/' + storeInfo['ecwid_store_id'] + '#order:id=' + props?.ecwidOrderId + '&return=orders');
            }} />
        </div>)
}

export default OrderCard;
