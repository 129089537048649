import React, { forwardRef, useRef, useState } from "react";
import DatePicker, { registerLocale } from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import './styles/filter-date-range.scss';
import { t } from "i18next";
import en from "date-fns/locale/en-GB";

registerLocale('en-GB', en);

export const FilterDateRange = (props) => {
    const dropdown = useRef(null);

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const clear = () => {
        setStartDate(null);
        setEndDate(null);

        dropdown.current.classList.remove('filled');
        dropdown.current.classList.add('opened');

        props.onChangeStartDate(null);
        props.onChangeEndDate(null);
    }

    const setStartDateDatePicker = (date) => {
        setStartDate(date);

        props.onChangeStartDate(date);
    };

    const setEndDateDatePicker = (date) => {
        setEndDate(date);

        props.onChangeEndDate(date);
    };

    const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ];

    const StartDateInput = forwardRef(({ value, onClick, onChange }, ref) => (
        <div className="fieldset fieldset--with-label"
            onClick={onClick}>
            <div className={"field field--medium" + (startDate ? " field--filled" : "")}>
                <span className="fieldset__svg-icon" />
                <label className="field__label">{t('dashboard.orders_listing.filters.start_date')}</label>
                <input type="text"
                    id="startDateIntervalCompany"
                    className="field__input"
                    ref={ref}
                    readOnly
                    value={value}
                    onChange={onChange}
                />
                <div className="field__placeholder">{t('dashboard.orders_listing.filters.start_date')}</div>
                <span className="field-state--success">
                    <svg xmlns="http://www.w3.org/2000/svg" width="26px"
                        height="26px" viewBox="0 0 26 26" focusable="false">
                        <path
                            d="M5 12l5.02 4.9L21.15 4c.65-.66 1.71-.66 2.36 0 .65.67.65 1.74 0 2.4l-12.3 14.1c-.33.33-.76.5-1.18.5-.43 0-.86-.17-1.18-.5l-6.21-6.1c-.65-.66-.65-1.74 0-2.41.65-.65 1.71-.65 2.36.01z" /></svg>
                </span>
                <span className="field-state--close">
                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                        viewBox="0 0 16 16" enableBackground="new 0 0 16 16"
                        xmlSpace="preserve" focusable="false">
                        <path
                            d="M15.6,15.5c-0.53,0.53-1.38,0.53-1.91,0L8.05,9.87L2.31,15.6c-0.53,0.53-1.38,0.53-1.91,0c-0.53-0.53-0.53-1.38,0-1.9l5.65-5.64L0.4,2.4c-0.53-0.53-0.53-1.38,0-1.91c0.53-0.53,1.38-0.53,1.91,0l5.64,5.63l5.74-5.73c0.53-0.53,1.38-0.53,1.91,0c0.53,0.53,0.53,1.38,0,1.91L9.94,7.94l5.66,5.65C16.12,14.12,16.12,14.97,15.6,15.5z" /></svg>
                </span>
            </div>
            <div className="field__error" aria-hidden="true" style={{ display: 'none' }} />
        </div>
    ));

    const EndDateInput = forwardRef(({ value, onClick, onChange }, ref) => (
        <div className="fieldset fieldset--with-label"
            onClick={onClick}
            style={{ marginRight: '0' }}>
            <div className={"field field--medium" + (endDate ? " field--filled" : "")}>
                <span className="fieldset__svg-icon" />
                <label className="field__label">{t('dashboard.orders_listing.filters.end_date')}</label>
                <input type="text"
                    id="endDateIntervalCompany"
                    className="field__input"
                    ref={ref}
                    readOnly
                    value={value}
                    onChange={onChange}
                />
                <div className="field__placeholder">{t('dashboard.orders_listing.filters.end_date')}</div>
                <span className="field-state--success">
                    <svg xmlns="http://www.w3.org/2000/svg" width="26px"
                        height="26px" viewBox="0 0 26 26" focusable="false">
                        <path
                            d="M5 12l5.02 4.9L21.15 4c.65-.66 1.71-.66 2.36 0 .65.67.65 1.74 0 2.4l-12.3 14.1c-.33.33-.76.5-1.18.5-.43 0-.86-.17-1.18-.5l-6.21-6.1c-.65-.66-.65-1.74 0-2.41.65-.65 1.71-.65 2.36.01z" /></svg>
                </span>
                <span className="field-state--close">
                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                        viewBox="0 0 16 16" enableBackground="new 0 0 16 16"
                        xmlSpace="preserve" focusable="false">
                        <path
                            d="M15.6,15.5c-0.53,0.53-1.38,0.53-1.91,0L8.05,9.87L2.31,15.6c-0.53,0.53-1.38,0.53-1.91,0c-0.53-0.53-0.53-1.38,0-1.9l5.65-5.64L0.4,2.4c-0.53-0.53-0.53-1.38,0-1.91c0.53-0.53,1.38-0.53,1.91,0l5.64,5.63l5.74-5.73c0.53-0.53,1.38-0.53,1.91,0c0.53,0.53,0.53,1.38,0,1.91L9.94,7.94l5.66,5.65C16.12,14.12,16.12,14.97,15.6,15.5z" /></svg>
                </span>
            </div>
            <div className="field__error" aria-hidden="true" style={{ display: 'none' }} />
        </div>
    ));

    return (
        <div
            className={"vertical-filters__dropdowns-list-item dropdown-filter-panel products opened" + (startDate || endDate ? " filled" : "")}
            style={{ borderTop: '1px solid #c5ccd3' }}
            ref={dropdown}
            onClick={() => dropdown.current.classList.add('opened')}
        >
            <div className="dropdown-filter-panel__header" style={{ cursor: 'auto' }}>
                <div className="dropdown-filter-panel__header-title">{props.title}</div>
                <div
                    className="dropdown-filter-panel__header-clear"
                    onClick={() => clear()}
                >
                    {t('dashboard.orders_listing.filters.clear')}
                </div>
            </div>
            <div className="">
                <div className="ecwid-orders-filter-popup">
                    <div className="options-list__search" aria-hidden="true" style={{ display: 'none' }} />
                    <div className="d-flex justify-content-between">
                        <DatePicker
                            customInput={<StartDateInput />}
                            dateFormat="dd.MM.yyyy"
                            locale="en-GB"
                            selected={startDate}
                            onChange={(date) => setStartDateDatePicker(date)}
                            shouldCloseOnSelect={false}
                            renderCustomHeader={({
                                date,
                                changeYear,
                                changeMonth,
                                decreaseMonth,
                                increaseMonth,
                                prevMonthButtonDisabled,
                                nextMonthButtonDisabled,
                            }) => (
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        margin: "20px 16px 8px 16px",
                                        fontSize: "12px",
                                        lineHeight: "18px",
                                        fontWeight: "600"
                                    }}
                                >
                                    {months[date.getMonth()]} {date.getFullYear()}
                                    <div className="d-flex justify-content-between align-items-center">
                                        <button className="months-button cursor-pointer" onClick={decreaseMonth}
                                            disabled={prevMonthButtonDisabled}>
                                            <svg width="6" height="18" viewBox="0 0 6 18" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" clipRule="evenodd"
                                                    d="M5.72243 12.221L2.98651 9.08207L5.72243 5.78052C6.09094 5.37408 6.09094 4.71127 5.72243 4.30483C5.34833 3.89839 4.75089 3.89839 4.38238 4.30483L0.976441 8.33797C0.792185 8.53806 0.697266 8.80694 0.697266 9.07582C0.697266 9.34469 0.792185 9.61357 0.976441 9.81366L4.38238 13.6967C4.75089 14.1032 5.35391 14.1032 5.72801 13.6967C6.09094 13.2903 6.09094 12.6275 5.72243 12.221Z"
                                                    fill={prevMonthButtonDisabled ? '#ABB5C0' : '#068EEF'}
                                                />
                                            </svg>
                                        </button>
                                        <button className="months-button cursor-pointer" onClick={increaseMonth}
                                            disabled={nextMonthButtonDisabled}>
                                            <svg width="6" height="18" viewBox="0 0 6 18" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" clipRule="evenodd"
                                                    d="M0.277575 12.221L3.01349 9.08207L0.277575 5.78052C-0.0909371 5.37408 -0.0909371 4.71127 0.277575 4.30483C0.65167 3.89839 1.24911 3.89839 1.61762 4.30483L5.02356 8.33797C5.20781 8.53806 5.30273 8.80694 5.30273 9.07582C5.30273 9.34469 5.20781 9.61357 5.02356 9.81366L1.61762 13.6967C1.24911 14.1032 0.646086 14.1032 0.271991 13.6967C-0.0909376 13.2903 -0.0909371 12.6275 0.277575 12.221Z"
                                                    fill={nextMonthButtonDisabled ? '#ABB5C0' : '#068EEF'}
                                                />
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                            )}
                        >
                            <div className="react-datepicker_button_clear">
                                <div className="react-datepicker_button_clear_wrapper">
                                    <button className="btn btn-link" onClick={() => setStartDate(null)}>{t('dashboard.orders_listing.filters.date_clear')}</button>
                                </div>
                            </div>
                        </DatePicker>
                        <DatePicker
                            customInput={<EndDateInput />}
                            dateFormat="dd.MM.yyyy"
                            selected={endDate}
                            locale="en-GB"
                            minDate={startDate}
                            onChange={(date) => setEndDateDatePicker(date)}
                            shouldCloseOnSelect={false}
                            renderCustomHeader={({
                                date,
                                changeYear,
                                changeMonth,
                                decreaseMonth,
                                increaseMonth,
                                prevMonthButtonDisabled,
                                nextMonthButtonDisabled,
                            }) => (
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        margin: "20px 16px 8px 16px",
                                        fontSize: "12px",
                                        lineHeight: "18px",
                                        fontWeight: "600"
                                    }}
                                >
                                    {months[date.getMonth()]} {date.getFullYear()}
                                    <div className="d-flex justify-content-between align-items-center">
                                        <button className="months-button cursor-pointer" onClick={decreaseMonth}
                                            disabled={prevMonthButtonDisabled}>
                                            <svg width="6" height="18" viewBox="0 0 6 18" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" clipRule="evenodd"
                                                    d="M5.72243 12.221L2.98651 9.08207L5.72243 5.78052C6.09094 5.37408 6.09094 4.71127 5.72243 4.30483C5.34833 3.89839 4.75089 3.89839 4.38238 4.30483L0.976441 8.33797C0.792185 8.53806 0.697266 8.80694 0.697266 9.07582C0.697266 9.34469 0.792185 9.61357 0.976441 9.81366L4.38238 13.6967C4.75089 14.1032 5.35391 14.1032 5.72801 13.6967C6.09094 13.2903 6.09094 12.6275 5.72243 12.221Z"
                                                    fill={prevMonthButtonDisabled ? '#ABB5C0' : '#068EEF'}
                                                />
                                            </svg>
                                        </button>
                                        <button className="months-button cursor-pointer" onClick={increaseMonth}
                                            disabled={nextMonthButtonDisabled}>
                                            <svg width="6" height="18" viewBox="0 0 6 18" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" clipRule="evenodd"
                                                    d="M0.277575 12.221L3.01349 9.08207L0.277575 5.78052C-0.0909371 5.37408 -0.0909371 4.71127 0.277575 4.30483C0.65167 3.89839 1.24911 3.89839 1.61762 4.30483L5.02356 8.33797C5.20781 8.53806 5.30273 8.80694 5.30273 9.07582C5.30273 9.34469 5.20781 9.61357 5.02356 9.81366L1.61762 13.6967C1.24911 14.1032 0.646086 14.1032 0.271991 13.6967C-0.0909376 13.2903 -0.0909371 12.6275 0.277575 12.221Z"
                                                    fill={nextMonthButtonDisabled ? '#ABB5C0' : '#068EEF'}
                                                />
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                            )}
                        >
                            <div className="react-datepicker_button_clear">
                                <div className="react-datepicker_button_clear_wrapper">
                                    <button className="btn btn-link" onClick={() => setEndDate(null)}>{t('dashboard.orders_listing.filters.date_clear')}</button>
                                </div>
                            </div>
                        </DatePicker>
                    </div>
                </div>
            </div>
        </div>
    );
}