import { useState, useEffect } from 'react';
import { t } from 'i18next';

const useValidation = (value, defaultValue, validations) => {
    const [valid, setValid] = useState();
    const [message, setMessage] = useState('');

    if (typeof value === 'string') {
        value = value.trim();
    }

    function isFloat(n) {
        return Number.isInteger(!Number.isNaN(Number(n)) && Number(n)) || (!Number.isNaN(Number(n)) && Number(n) % 1 !== 0, n, Number(n));
    }

    useEffect(() => {
        /* eslint eqeqeq: 0 */
        if (value == defaultValue) {
            setValid('');
            return valid;
        }
 
        for (const validation in validations) {
            if (validation === 'isEmpty') {
                if (!value) {
                    setValid('has-error');
                    setMessage(t('requiredField'));
                    break;
                }
                setValid('has-success');
            }
            if (validation === 'minLength') {
                if (value.length < validations[validation]) {
                    setValid('has-error');
                    setMessage(`The field must be ${validations[validation]} digits.`);
                    break;
                }
                setValid('has-success');
            }
            if (validation === 'isInteger') {
                if (!Number.isInteger(Number(value))) {
                    setValid('has-error');
                    setMessage(t('fieldNumber'));
                    break;
                }
                setValid('has-success');
            }
            if (validation === 'isFloat') {
                if (!isFloat(value)) {
                    setValid('has-error');
                    setMessage(t('fieldNumber'));
                    break;
                }
                setValid('has-success');
            }
        }

    }, [value, defaultValue, validations, valid]);

    return {
        valid,
        setValid,
        message,
        setMessage
    };
}

const useInput = (initialValue, defaultValue, validations) => {
    const [value, setValue] = useState(initialValue);

    const { valid, setValid, message, setMessage } = useValidation(value, defaultValue, validations);
    const [changed, setChanged] = useState(false);

    const onChange = (e) => {
        setValue(e.target.value);

        if (!changed) {
            setChanged(true);
        }
    }

    const reset = () => {
        setValue(initialValue);
        setValid('');
    }

    return {
        reset,
        valid,
        value,
        onChange,
        setValue,
        changed,
        message,
        setValid,
        setMessage
    }
}

const useForm = (formInputs) => {
    const [formStatus, setFormStatus] = useState();
    useEffect(() => {
        if (formStatus !== 'saved') {
            if (formInputs.some(input => input.valid === 'has-error')) {
                setFormStatus('error');
            } else if (formInputs.some(input => input.valid === 'has-success')) {
                setFormStatus('unsaved');
            } else {
                setFormStatus('');
            }
        }
    }, [formInputs, formStatus]);

    const saved = () => {
        setFormStatus('saved');
        setTimeout(() => {
            setFormStatus('');
        }, 1000);
    }

    const error = () => {
        setFormStatus('error');
    }

    return {
        formStatus,
        saved,
        error,
    }
}

export { useInput, useForm };