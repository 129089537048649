import EcwidInfoEndpoint from './EcwidInfoEndpoint';
import OrdersInfoEndpoints from './OrdersInfoEndpoints';
// import ProductsEndpoint from "./ProductsEndpoint";
// import StatisticsEndpoint from "./StatisticsEndpoint";

// eslint-disable-next-line
export default [
  {name: 'ecwidInfo', endpoint: EcwidInfoEndpoint},
  {name: 'orders', endpoint: OrdersInfoEndpoints},
  // {name: 'products', endpoint: ProductsEndpoint},
  // {name: 'statistics', endpoint: StatisticsEndpoint},
];
