import { React } from 'react';
// import { t } from 'i18next';
import { useTranslation } from 'react-i18next';
import './styles/dashboard.sass';
import SettingsPage from "../SettingsPage/SettingsPage";
import HeaderSettingPage from "../SettingsPage/components/HeaderSettingPage/HeaderSettingPage";
import ContentAreaSettingPage from "../SettingsPage/components/ContentAreaSettingPage/ContentAreaSettingPage";
import ContentBodySettingPage from "../SettingsPage/components/ContentBodySettingPage/ContentBodySettingPage";
import ContentAreaBody from "../SettingsPage/components/ContentAreaSettingPage/components/ContentAreaBody/ContentAreaBody";
import ContentAreaHeader from "../SettingsPage/components/ContentAreaSettingPage/components/ContentAreaHeader/ContentAreaHeader";
//Help window
import Dialog from "../Dialog/Dialog";
import UserGuideEn from "../Dialog/UserGuide/UserGuideEn";
import UserGuideIt from "../Dialog/UserGuide/UserGuideIt";
// UI/UX ecwid components
import Acard from '../EcwidComponents/Acard/Acard';
import {
    Form,
    FormTitle,
    FormContent,
    Fieldsets,
    FieldsetInput,
    FieldsetInputPassword
    // FieldsetFake
} from '../EcwidComponents/Form/Form';
import { useStore } from "../../store";
import { useInput, useForm } from '../../hooks/useForm';
import { FilteredList } from '../EcwidComponents/FilteredList/FilteredList';

const Dashboard = () => {
    const {
        ecwidStore: { storeInfo, storeUpdate },
    } = useStore();

    const { t } = useTranslation();

    //!BACKLOG: Обновить до полной автоматизации, через useForm(Сделать автоматическую сборку input полей).
    const login = useInput(storeInfo.brt_login, storeInfo.brt_login, { isEmpty: true, minLength: 6, isInteger: true });
    const password = useInput(storeInfo.brt_password, storeInfo.brt_password, { isEmpty: true, minLength: 8 });
    const userId = useInput(storeInfo.brt_user_id, storeInfo.brt_user_id, { isEmpty: true, minLength: 3, isInteger: true });
    const agencyCode = useInput(storeInfo.brt_agency_code, storeInfo.brt_agency_code, { isEmpty: true, minLength: 3, isInteger: true });
    const comissionCode = useInput(storeInfo.brt_comission_code, storeInfo.brt_comission_code, { isEmpty: true, minLength: 3, isInteger: true });
    const LoginForm = useForm([login, password, userId, agencyCode, comissionCode]);

    // const defaultLengthInCm = useInput(storeInfo.default_length_in_cm, { isEmpty: true, isInteger: true });
    // const defaultWidthInCm = useInput(storeInfo.default_width_in_cm, { isEmpty: true, isInteger: true });
    // const defaultHeightInCm = useInput(storeInfo.default_height_in_cm, { isEmpty: true, isInteger: true });
    // const defaultWeightInKg = useInput(storeInfo.default_weight_in_kg, { isEmpty: true, isInteger: true });
    // const GabariteForm = useForm([defaultLengthInCm, defaultWidthInCm, defaultHeightInCm, defaultWeightInKg]);

    return (
        <SettingsPage>
            <Dialog
                title={t('user_guide.title')}
            >
                {storeInfo && storeInfo.lang === 'it' ? <UserGuideIt /> : <UserGuideEn />}
            </Dialog>

            <HeaderSettingPage
                title={t('integrationTitle')}
                helpButton={true}
            />
            <ContentBodySettingPage>
                <ContentAreaSettingPage>
                    <ContentAreaHeader
                        title={t('dashboard.delivery_setting.header')}
                        description={t('dashboard.delivery_setting.description')}
                    />
                    <ContentAreaBody>
                        <Acard
                            cardStatus={LoginForm.formStatus}
                            onClickSave={() => {
                                const params = {
                                    data: {
                                        "brt_login": login.value,
                                        "brt_password": password.value,
                                        "brt_user_id": userId.value,
                                        "brt_agency_code": agencyCode.value,
                                        "brt_comission_code": comissionCode.value,
                                    }
                                }

                                storeUpdate(params)
                                    .then((response) => {
                                        storeInfo.brt_login = login.value;
                                        storeInfo.brt_password = password.value;
                                        storeInfo.brt_user_id = userId.value;
                                        storeInfo.brt_agency_code = agencyCode.value;
                                        storeInfo.brt_comission_code = comissionCode.value;
                                        LoginForm.saved();
                                    });
                            }}
                            onClickCancel={() => {
                                login.reset();
                                password.reset();
                                userId.reset();
                                agencyCode.reset();
                                comissionCode.reset();
                            }}
                        >
                            <Form>
                                <FormTitle>
                                    <h4>{t('dashboard.delivery_setting.formTitle')}</h4>
                                </FormTitle>
                                <FormContent>
                                    <Fieldsets>
                                        <FieldsetInput
                                            title={t('dashboard.delivery_setting.login')}
                                            placeholder={t('dashboard.delivery_setting.username')}
                                            attribute={login}
                                            // annotation={t('dashboard.delivery_setting.enterFromToCharacters')}
                                            value={login.value}
                                            statusClass={login.valid}
                                            name="brt_login"
                                            onChange={e => login.onChange(e)}
                                            invalidMessage={t('dashboard.delivery_setting.incorrect_login')}
                                        />
                                        <FieldsetInputPassword
                                            title={t('dashboard.delivery_setting.password')}
                                            placeholder="&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;"
                                            attribute={password}
                                            // annotation={t('dashboard.delivery_setting.enterFromToCharacters')}
                                            type="password"
                                            value={password.value}
                                            statusClass={password.valid}
                                            name="brt_password"
                                            onChange={e => password.onChange(e)}
                                            invalidMessage={t('dashboard.delivery_setting.incorrect_password')}
                                        />
                                    </Fieldsets>
                                </FormContent>
                            </Form>
                            <Form>
                                {/* <FormTitle> */}
                                {/* <h4>{t('dashboard.delivery_setting.For the application to work correctly, you need to fill in')}</h4> */}
                                {/* </FormTitle> */}
                                <FormContent>
                                    <Fieldsets>
                                        <FieldsetInput
                                            title={t('dashboard.delivery_setting.userId')}
                                            attribute={userId}
                                            value={userId.value}
                                            statusClass={userId.valid}
                                            placeholder={t('dashboard.delivery_setting.enter_value')}
                                            name="brt_user_id"
                                            onChange={e => userId.onChange(e)}
                                            invalidMessage={t('dashboard.delivery_setting.incorrect_userId')}
                                        />
                                        <FieldsetInput
                                            title={t('dashboard.delivery_setting.agencyCode')}
                                            attribute={agencyCode}
                                            value={agencyCode.value}
                                            statusClass={agencyCode.valid}
                                            placeholder={t('dashboard.delivery_setting.enter_value')}
                                            name="brt_agency_code"
                                            onChange={e => agencyCode.onChange(e)}
                                            invalidMessage={t('dashboard.delivery_setting.incorrect_agencyCode')}
                                        />
                                        <FieldsetInput
                                            title={t('dashboard.delivery_setting.commissionCode')}
                                            attribute={comissionCode}
                                            value={comissionCode.value}
                                            statusClass={comissionCode.valid}
                                            placeholder={t('dashboard.delivery_setting.enter_value')}
                                            name="brt_comission_code"
                                            onChange={e => comissionCode.onChange(e)}
                                            invalidMessage={t('dashboard.delivery_setting.incorrect_commissionCode')}
                                        />
                                    </Fieldsets>
                                </FormContent>
                            </Form>
                        </Acard>
                    </ContentAreaBody>
                </ContentAreaSettingPage>
                {/* <ContentAreaSettingPage>
                    <ContentAreaHeader
                        title={t('dashboard.package_dimensions_weight.header')}
                        description={t('dashboard.package_dimensions_weight.description')}
                    />
                    <ContentAreaBody>
                        <Acard
                            cardStatus={GabariteForm.formStatus}
                            onChange={(e) => GabariteForm.changeFormStatus(e)}
                            onClickSave={() => {
                                const params = {
                                    data: {
                                        "default_length_in_cm": defaultLengthInCm.value,
                                        "default_width_in_cm": defaultWidthInCm.value,
                                        "default_height_in_cm": defaultHeightInCm.value,
                                        "default_weight_in_kg": defaultWeightInKg.value,
                                    }
                                }
                                storeUpdate(params)
                                    .then((response) => {
                                        storeInfo.default_length_in_cm = defaultLengthInCm.value;
                                        storeInfo.default_width_in_cm = defaultWidthInCm.value;
                                        storeInfo.default_height_in_cm = defaultHeightInCm.value;
                                        storeInfo.default_weight_in_kg = defaultWeightInKg.value;
                                        GabariteForm.saved();
                                    });
                            }}
                            onClickCancel={() => {
                                defaultLengthInCm.reset();
                                defaultWidthInCm.reset();
                                defaultHeightInCm.reset();
                                defaultWeightInKg.reset();
                            }}>
                            <Form>
                                <FormTitle>
                                    <h4>{t('dashboard.package_dimensions_weight.title')}</h4>
                                </FormTitle>
                                <FormContent>
                                    <Fieldsets>
                                        <FieldsetInput
                                            title={t('dashboard.package_dimensions_weight.length')}
                                            placeholder={t('dashboard.package_dimensions_weight.enter_value')}
                                            attribute={defaultLengthInCm}
                                            value={defaultLengthInCm.value}
                                            statusClass={defaultLengthInCm.valid}
                                            name="default_length_in_cm"
                                            onChange={(e) => { defaultLengthInCm.onChange(e) }}
                                            invalidMessage={t('dashboard.package_dimensions_weight.incorrect_length')}
                                        />
                                        <FieldsetInput
                                            title={t('dashboard.package_dimensions_weight.width')}
                                            placeholder={t('dashboard.package_dimensions_weight.enter_value')}
                                            attribute={defaultWidthInCm}
                                            value={defaultWidthInCm.value}
                                            statusClass={defaultWidthInCm.valid}
                                            name="default_width_in_cm"
                                            onChange={(e) => { defaultWidthInCm.onChange(e) }}
                                            invalidMessage={t('dashboard.package_dimensions_weight.incorrect_width')}
                                        />
                                        <FieldsetInput
                                            title={t('dashboard.package_dimensions_weight.height')}
                                            placeholder={t('dashboard.package_dimensions_weight.enter_value')}
                                            attribute={defaultHeightInCm}
                                            value={defaultHeightInCm.value}
                                            statusClass={defaultHeightInCm.valid}
                                            name="default_height_in_cm"
                                            onChange={(e) => { defaultHeightInCm.onChange(e) }}
                                            invalidMessage={t('dashboard.package_dimensions_weight.incorrect_height')}
                                        />
                                    </Fieldsets>
                                </FormContent>
                            </Form>
                            <Form>
                                <FormTitle>
                                    <h4>{t('dashboard.package_dimensions_weight.package_weight')}</h4>
                                </FormTitle>
                                <FormContent>
                                    <Fieldsets>
                                        <FieldsetInput
                                            title={t('dashboard.package_dimensions_weight.weight')}
                                            placeholder={t('dashboard.package_dimensions_weight.enter_value')}
                                            attribute={defaultWeightInKg}
                                            value={defaultWeightInKg.value}
                                            statusClass={defaultWeightInKg.valid}
                                            name="default_weight_in_kg"
                                            onChange={(e) => { defaultWeightInKg.onChange(e) }}
                                            invalidMessage={t('dashboard.package_dimensions_weight.incorrect_weight')}
                                        />
                                        <FieldsetFake />
                                        <FieldsetFake />
                                    </Fieldsets>
                                </FormContent>
                            </Form>
                        </Acard>
                    </ContentAreaBody>
                </ContentAreaSettingPage> */}
                <ContentAreaSettingPage>
                    <ContentAreaHeader
                        title={t('dashboard.orders_listing.header')}
                        description={t('dashboard.orders_listing.description')}
                    />
                    <ContentAreaBody>
                        <Acard>
                            <FilteredList>

                            </FilteredList>
                        </Acard>
                    </ContentAreaBody>
                </ContentAreaSettingPage>
            </ContentBodySettingPage>
        </SettingsPage >
    )
}

export default Dashboard;