import React from 'react';
import './styles/content-area-setting-page.sass';

const ContentAreaSettingPage = (props) => {
  const { children } = props;

  return (
    <div className="named-area">
      { children }
    </div>
  )
}

export default ContentAreaSettingPage;