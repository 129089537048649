import { t } from "i18next";
import React from "react";
import './styles/orders-sync.scss';

export const OrdersSync = (props) => {

    return (
        <div className="orders-sync">
            <div className="d-flex flex-column">
                <h3>{t('ordersSync.title')}</h3>
                {props.statusStart && <p>
                    {t('ordersSync.prefix')}
                </p>}
                {props.current > 0 && <p>{t('ordersSync.contentPreCurrent')} {props.current} {t('ordersSync.from')} {props.total} {t('ordersSync.orders')}</p>}
            </div>
        </div>
    )
}