export default function UserGuideIt() {
    return (
        <div>
            <div className="user-guide-step">
                <h3>1. Getting started</h3>
                <p>
                    BRT è un Corriere Espresso veloce ed affidabile, specializzato nella consegna di spedizioni di vario
                    tipo e nella fornitura di servizi di supporto logistico per la movimentazione e distribuzione di
                    prodotti. Con BRT puoi migliorare la soddisfazione dei tuoi clienti e dimenticare pacchi in ritardo
                    o smarriti. Questa app integra il servizio BRT nel tuo negozio online.
                </p>
                <p>
                    La app ti permette di scegliere gli ordini che vuoi consegnare con BRT e di tracciare i pacchi.
                </p>
                <p>
                    Per utilizzarla è necessario disporre di un account BRT. Se non ne possiedi uno, richiedila
                    contattando BRT. Le istruzioni dettagliate per la configurazione dell'account BRT sono disponibili
                    nella sezione seguente.
                </p>
            </div>
            <div className="user-guide-step">
                <h3>2. Configurazione dell’account BRT</h3>
                <p>
                    Per abilitare lo spedizioniere BRT è necessario contattarlo per aprire il tuo account e ricevere
                    tutte le istruzioni; se vuoi puoi contattare prima il tuo riferimento IOL per maggiori informazioni.
                </p>
            </div>
            <div className="user-guide-step">
                <h3>3. Integrazione dell’account BRT</h3>
                <p>
                    Per prima cosa devi collegare il tuo account BRT al Negozio Online. Per farlo, inserisci negli
                    appositi campi di testo il tuo ID, la password e le altre credenziali relative al tuo account. Si
                    prega di verificare attentamente che tutte le credenziali siano corrette.
                </p>
            </div>
            <div className="user-guide-step">
                <h3>4. Numero di telefono</h3>
                <p>
                    Tutti gli ordini inviati a BRT per la gestione devono contenere il numero di telefono del cliente.
                    Per questo motivo è necessario richiedere il telefono al cliente in fase di checkout.
                    A tal fine abilita la funzione “Richiedi il numero di telefono alla cassa” nella sezione
                    “Impostazioni Generali” -> “Carrello” -> “Impostazioni di pagamento”.
                </p>
                <h3>5. Nome dell’Azienda</h3>
                <p>
                    Per garantire la corretta gestione degli ordini con BRT, il Nome dell’Azienda dovrebbe avere meno di
                    25 caratteri.
                    Assicurati della lunghezza del Nome dell’Azienda nella sezione Impostazioni -> Generale -> Profilo
                    del Negozio.
                </p>
                <h3>6. Settaggio della tabella pesi/costi</h3>
                <p>
                    L'opzione di consegna BRT non sarà possibile a meno che non si modifichino le impostazioni di
                    spedizione e ritiro.
                </p>
                <span className="text-big">Per abilitare l'opzione di spedizione BRT al momento del pagamento:</span>
                <ol>
                    <li>Dall'amministratore del tuo negozio online, vai su Spedizioni e ritiro.</li>
                    <li>Fai clic su + Aggiungi spedizione.</li>
                    <li>Seleziona "Altri vettori" e compila "BRT" nel campo di testo.</li>
                    <li>Fai clic su Configura.</li>
                    <li>Fare clic su Imposta tariffe personalizzate.</li>
                </ol>
                <span className="text-big">Ora, compila tutti i campi richiesti:</span>
                <ol>
                    <li>In "Nome spedizione al checkout" inserire "Consegna BRT".</li>
                    <li>Fai clic sul menu a tendina "Tabella basata su" e scegli "Peso".</li>
                    <li>Imposta gli intervalli di peso a cui verranno applicate le tariffe. Fai clic su "Aggiungi riga"
                        per le righe aggiuntive nella tabella.
                    </li>
                    <li>Imposta la tariffa di spedizione che desideri addebitare per ciascuna fascia di peso. Se lo
                        desideri, vai sul sito BRT per calcolare il costo di una spedizione per ogni fascia di peso.
                    </li>
                </ol>
                <p>
                    Una volta creato il nuovo metodo di spedizione BRT, i clienti lo potranno vedere pre-selezionato
                    come opzione di consegna nel checkout.
                </p>
                <p><b>Attenzione!</b> La app consente di utilizzare il servizio BRT per tutti gli ordini creati,
                    indipendentemente dal metodo di spedizione scelto dal cliente.</p>
                <h3>7. Gestione degli ordini</h3>
                <p>In questa sezione puoi gestire i tuoi ordini e abilitare la spedizione con BRT o selezionare altre
                    modalità di spedizione e consegna.</p>
                <span className="text-big">Per abilitare la consegna con BRT, completa i seguenti passaggi:</span>
                <p className="muted">[Immagine di esempio]</p>
                <ol>
                    <li>Modifica lo stato dell'ordine in "Crea richiesta" per avviare il processo. Riceverai notifiche
                        quando lo stato viene modificato. Dopo questo passaggio, verrà generato il etichetta della
                        spedizione (etichetta in formato PDF). Una volta completato questo passaggio, potrai procedere
                        alla conferma.
                    </li>
                    <li>Modifica lo stato dell'ordine in "Conferma richiesta". Sarai avvisato quando lo stato
                        cambierà.
                    </li>
                    <li>L'ultimo passo è inviare la richiesta di ritiro a BRT. Per farlo, cambia lo stato in "Invia
                        richiesta". BRT riceve la richiesta, e a questo punto gli ordini saranno ritirati al più presto
                        e consegnati ai clienti. Sarai in grado di monitorare lo stato di consegna.
                    </li>
                </ol>
                <h3>8. Tracciamento dell’ordine</h3>
                <p>Lo stato BRT mostra lo stato di avanzamento della spedizione dei tuoi ordini, a partire da una
                    richiesta appena creata fino a un pacco consegnato. Puoi filtrare i tuoi ordini per stato BRT per
                    monitorare il processo.</p>
                <span className="text-big">Lo stato BRT può assumere i seguenti valori:</span>
                <ol>
                    <li>In attesa del processo di creazione dell’etichetta / In attesa</li>
                    <li>Etichetta creata</li>
                    <li>Etichetta confermata</li>
                    <li>Dati di consegna trasmessi a BRT</li>
                    <li>Pacco spedito</li>
                    <li>Partito</li>
                    <li>Arrivato in filiale</li>
                    <li>In Consegna</li>
                    <li>Consegnato</li>
                </ol>
                <p>
                    Quando un cliente completa la procedura di pagamento, il suo ordine apparirà nella sezione "Ordini"
                    della app proprio come in "Ordini". Per impostazione predefinita, l'elenco degli ordini è
                    organizzato dal più recente al meno recente. Una barra di ricerca ti consente di trovare gli ordini
                    per numero d'ordine, nome del prodotto, SKU o altri dettagli. Gli ordini possono anche essere
                    filtrati per data, stato di pagamento e di evasione, ecc…
                </p>
                <p>
                    Puoi anche filtrare i tuoi ordini per stato di consegna.
                </p>
            </div>
        </div>
    );
}