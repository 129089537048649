const getOrdersInfo = (api, orderId, startDate, endDate, paymentStatus, fulfillmentStatus, brtStatus, minPrice, maxPrice, page) => new Promise((resolve) => {
    api.endpoints.orders.getOrderInfo({ orderId, startDate, endDate, paymentStatus, fulfillmentStatus, brtStatus, minPrice, maxPrice, page })
        .then(orders => {
            // Storage.set('currentPage', orders.currentPage);
            // getRootStore('ecwidStore').orders = orders;
            resolve(orders);
        })
        .catch(error => {
            console.error(error);
            resolve(error);
        })
});

export default getOrdersInfo;
