import React from 'react';
import './styles/settings-page.scss';

const SettingsPage = ({children, props}) => {
    return (
        <div className="settings-page settings-page_background cf" style={{ paddingBottom: '150px' }}>
            {children}
        </div>
    )
}

export default SettingsPage;