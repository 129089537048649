import React from 'react';
import './styles/route_block.sass';

export const RouteBlock = (props) => {
    if (props?.trackingEvents) {
        return (
            <div className="route">
                {props?.trackingEvents.map((event) => {
                    if (event?.evento?.filiale) {
                        return <div>
                            <div key={event?.id} className="destination">
                                {event?.evento?.filiale}
                            </div>
                            <div className="shipment-id">
                                {event?.evento?.data} - {event?.evento?.ora}
                            </div>
                        </div>
                    }
                })}
                {/* {<div className="origin">Delivered</div>} */}
            </div>
        )
    } else {
        return <div />;
    }
}
