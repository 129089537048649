import React, {useEffect, useRef, useState} from "react";
import {t} from "i18next";

export const FilterInputsRange = (props) => {
    const dropdown = useRef(null);
    const minInput = useRef(null);
    const maxInput = useRef(null);

    const [start, setStart] = useState('');
    const [end, setEnd] = useState('');

    const { onChange } = props;

    useEffect(() => {
        onChange(start, end);
    }, [start, end, onChange]);

    const clear = () => {
        setStart('');
        setEnd('');

        dropdown.current.classList.remove('filled');
        dropdown.current.classList.add('opened');

        minInput.current.classList.remove('field--filled')
        maxInput.current.classList.remove('field--filled')
    }

    const changeStart = (event) => {
        const value = (event.target.validity.valid) ?
            event.target.value : start;

        setStart(value);

        _toggleFilled(value);
    }

    const changeEnd = (event) => {
        const value = (event.target.validity.valid) ?
            event.target.value : end;

        setEnd(value);

        _toggleFilled(value);
    }

    const _toggleFilled = (value) => {
        if (value) {
            dropdown.current.classList.add('filled');
        } else {
            dropdown.current.classList.remove('filled');
        }
    }

    return (
        <div
            className="vertical-filters__dropdowns-list-item dropdown-filter-panel products opened"
            style={{borderTop: '1px solid #c5ccd3'}}
            ref={dropdown}
        >
            <div className="dropdown-filter-panel__header">
                <div className="dropdown-filter-panel__header-title">{props.title}</div>
                <div className="dropdown-filter-panel__header-icon"/>
                <div
                    className="dropdown-filter-panel__header-clear"
                    onClick={() => clear()}
                >
                    {t('dashboard.orders_listing.filters.clear')}
                </div>
            </div>
            <div className="dropdown-filter-panel__body dropdown-filter-panel__body--noscroll">
                <div className="ecwid-orders-filter-popup">
                    <div className="options-list__search" aria-hidden="true" style={{display: 'none'}}/>
                    <div className="d-flex justify-content-between">
                        <div className="fieldset fieldset--with-label mr-2">
                            <div className="field field--medium" ref={minInput}>
                                <span className="fieldset__svg-icon"/>
                                <label className="field__label">{t('dashboard.orders_listing.filters.min_price')}</label>
                                <input type="text"
                                       className="field__input"
                                       maxLength="64"
                                       pattern="[0-9]*"
                                       value={start}
                                       onChange={(event) => changeStart(event)}
                                />
                                <div className="field__placeholder">{t('dashboard.orders_listing.filters.min_price')}</div>
                                <span className="field-state--success">
                                    <svg xmlns="http://www.w3.org/2000/svg"
                                         width="26px" height="26px"
                                         viewBox="0 0 26 26" focusable="false"><path
                                        d="M5 12l5.02 4.9L21.15 4c.65-.66 1.71-.66 2.36 0 .65.67.65 1.74 0 2.4l-12.3 14.1c-.33.33-.76.5-1.18.5-.43 0-.86-.17-1.18-.5l-6.21-6.1c-.65-.66-.65-1.74 0-2.41.65-.65 1.71-.65 2.36.01z"/></svg></span>
                                <span className="field-state--close">
                                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg"
                                         xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 16 16"
                                         enableBackground="new 0 0 16 16" xmlSpace="preserve" focusable="false">
                                        <path
                                            d="M15.6,15.5c-0.53,0.53-1.38,0.53-1.91,0L8.05,9.87L2.31,15.6c-0.53,0.53-1.38,0.53-1.91,0c-0.53-0.53-0.53-1.38,0-1.9l5.65-5.64L0.4,2.4c-0.53-0.53-0.53-1.38,0-1.91c0.53-0.53,1.38-0.53,1.91,0l5.64,5.63l5.74-5.73c0.53-0.53,1.38-0.53,1.91,0c0.53,0.53,0.53,1.38,0,1.91L9.94,7.94l5.66,5.65C16.12,14.12,16.12,14.97,15.6,15.5z"/>
                                    </svg>
                                </span>
                            </div>
                            <div className="field__error" aria-hidden="true" style={{display: 'none'}}/>
                        </div>
                        <div className="fieldset fieldset--with-label">
                            <div className="field field--medium" ref={maxInput}>
                                <span className="fieldset__svg-icon"/>
                                <label className="field__label">{t('dashboard.orders_listing.filters.max_price')}</label>
                                <input type="text"
                                       className="field__input"
                                       maxLength="64"
                                       pattern="[0-9]*"
                                       value={end}
                                       onChange={(event) => changeEnd(event)}
                                />
                                <div className="field__placeholder">{t('dashboard.orders_listing.filters.max_price')}</div>
                                <span className="field-state--success">
                                    <svg xmlns="http://www.w3.org/2000/svg"
                                         width="26px" height="26px"
                                         viewBox="0 0 26 26" focusable="false"><path
                                        d="M5 12l5.02 4.9L21.15 4c.65-.66 1.71-.66 2.36 0 .65.67.65 1.74 0 2.4l-12.3 14.1c-.33.33-.76.5-1.18.5-.43 0-.86-.17-1.18-.5l-6.21-6.1c-.65-.66-.65-1.74 0-2.41.65-.65 1.71-.65 2.36.01z"/></svg></span>
                                <span className="field-state--close">
                                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg"
                                         xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 16 16"
                                         enableBackground="new 0 0 16 16" xmlSpace="preserve" focusable="false">
                                        <path
                                            d="M15.6,15.5c-0.53,0.53-1.38,0.53-1.91,0L8.05,9.87L2.31,15.6c-0.53,0.53-1.38,0.53-1.91,0c-0.53-0.53-0.53-1.38,0-1.9l5.65-5.64L0.4,2.4c-0.53-0.53-0.53-1.38,0-1.91c0.53-0.53,1.38-0.53,1.91,0l5.64,5.63l5.74-5.73c0.53-0.53,1.38-0.53,1.91,0c0.53,0.53,0.53,1.38,0,1.91L9.94,7.94l5.66,5.65C16.12,14.12,16.12,14.97,15.6,15.5z"/>
                                    </svg>
                                </span>
                            </div>
                            <div className="field__error" aria-hidden="true" style={{display: 'none'}}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}