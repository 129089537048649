import axios from "axios";
import endpoints from "./endpoints";
import Storage from "../utils/Storage";
import links from "../constants/links";
import history from "../utils/history";

class Api {
    constructor({ payload, getRootStore }) {
        this.axios = axios.create({
            baseURL: process.env.REACT_APP_BASE_URL,
        });

        this.getRootStore = getRootStore;
        this.endpoints = {};
        endpoints.forEach(({ name, endpoint }) => {
            this.endpoints[name] = new endpoint(this.axios, getRootStore);
        });
        this._init(payload);
    }

    _setPayload = (payload) => {
        this.axios.defaults.params = { payload };
    };

    setRequestCurrency = () => {
        this.axios.defaults.params.currency = Storage.get('currency');
    }

    _setInterceptorsResponse = () => {
        this.axios.interceptors.response.use(
            response => {
                return Promise.resolve(response);
            },
            async error => {
                const { somethingWentWrong, notFound } = links.pages;
                const errorObject = {};
                let httpCode = 500;
                if (error?.response) {
                    let isSpecialError = false;
                    switch (error.response.status) {
                        case 400 || 401: {
                            isSpecialError = true;
                            httpCode = error.response.status;
                            history.push(somethingWentWrong.path);
                            break;
                        }
                        case 404: {
                            isSpecialError = true;
                            httpCode = error.response.status;
                            history.push(notFound.path);
                            break;
                        }
                        // case 422: {
                        //     history.push(dashboard.path);
                        //     // isSpecialError = true;
                        //     // httpCode = error.response.status;
                        //     break;
                        // }
                        case 405:
                        case 500: {
                            // isSpecialError = true;
                            httpCode = error.response.status;
                            break;
                        }
                        default:
                            break;
                    }
                    errorObject.error = error.response?.data?.data?.error;
                    errorObject.isSpecialError = isSpecialError;
                    if (!errorObject.error) {
                        errorObject.error = { message: 'Something went wrong' };
                    }
                    errorObject.error.http_code = httpCode;
                    history.push(somethingWentWrong.path);
                } else {
                    errorObject.error = { message: error };
                    errorObject.isSpecialError = true;
                    errorObject.error.http_code = httpCode;
                    history.push(somethingWentWrong.path);
                }

                return Promise.reject(errorObject);
            })
    };

    _init = async (payload) => {
        this._setPayload(payload);
        this._setInterceptorsResponse();
    };
}

export default Api;
