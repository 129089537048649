class EcwidStore {
    loading = false;

    constructor({ api }, initialValue) {
        this.api = api;
        this.data = initialValue || null;
    }

    getStoreInfo = async () => {
        try {
            return await this.api.endpoints.ecwidInfo.getStoreInfo();
        } catch (error) {
            throw error;
        }
    }

    storeUpdate = async (params) => {
        try {
            return await this.api.endpoints.ecwidInfo.updateStore(params);
        } catch (error) {
            throw error;
        }
    }

    getOrders = async (params) => {
        try {
            return await this.api.endpoints.orders.getOrders(params);
        } catch (error) {
            throw error;
        }
    }

    getBarcode = async (orderId) => {
        try {
            return await this.api.endpoints.orders.getOrderBarcode(orderId);
        } catch (error) {
            throw error;
        }
    }

    saveChangedOrders = async (changedData) => {
        try {
            return await this.api.endpoints.ecwidInfo.saveChangedOrders(changedData);
        } catch (error) {
            throw error;
        }
    }

    getPdfReport = async (orders) => {
        try {
            return await this.api.endpoints.orders.getPdfReport(orders);
        } catch (error) {
            throw error;
        }
    }

}

export default EcwidStore;
