import apiList from "../../constants/apiList";
import moment from "moment";

// eslint-disable-next-line import/no-anonymous-default-export
export default class {
    constructor(axios) {
        this.axios = axios;
    };

    async getOrderInfo({ orderId, startDate, endDate, paymentStatus, fulfillmentStatus, brtStatus, minPrice, maxPrice, page }) {
        try {
            const filter = JSON.stringify({
                orderId: orderId,
                startDate: startDate ? moment(startDate).format('DD-MM-YYYY') : null,
                endDate: endDate ? moment(endDate).format('DD-MM-YYYY') : null,
                paymentStatus: paymentStatus,
                fulfillmentStatus: fulfillmentStatus,
                brtStatus: brtStatus,
                minPrice: +minPrice ? +minPrice : null,
                maxPrice: +maxPrice ? +maxPrice : null,
            });

            const response = await this.axios({
                method: 'get',
                url: apiList.ordersInfo,
                params: {
                    filter,
                    page: page
                }
            });
            return response.data.data;
        } catch (error) {
            throw error;
        }
    }

    async getOrder({ orderId }) {
        try {
            const response = await this.axios({
                method: 'get',
                url: apiList.ordersInfo + "/" + orderId,
            });
            return response.data.data;
        } catch (error) {
            throw error;
        }
    }

    async getOrderBarcode(orderId) {
        try {
            const response = await this.axios({
                method: 'get',
                url: apiList.orderBarcode,
                responseType: 'blob',
                headers: {
                    'Accept': 'application/pdf'
                },
                params: {
                    orderId
                }
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    }

    async getPdfReport(orders) {
        try {
            const response = await this.axios({
                method: 'post',
                url: apiList.pdf,
                responseType: 'blob',
                headers: {
                    'Accept': 'application/pdf'
                },
                data: {
                    ids:orders
                },
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    }
}
