import React from 'react';
import './styles/somethingWentWrong.sass';

function SomethingWentWrong() {
    return (
        <div className="somethingWentWrongContainer">
            <div className="contentWrapper">
                <div className="promo-block">
                    <div className="promo-block__picture cm-picture-error">
                        <img src={"https://my.ecwid.com/cp/icons/icon_face.svg"} alt="500 error" />
                    </div>
                </div>
                <h1 className='muted'>500</h1>
                <h2 className='muted'>
                    Something went wrong
                </h2>
            </div>
        </div>
    )
}

export default SomethingWentWrong;
