import React, {useEffect, useRef, useState} from "react";
import {t} from "i18next";

export const FilterAttributeList = (props) => {
    const dropdown = useRef(null);

    const [status, setStatus] = useState(props.default.value);

    const { onChange } = props;

    useEffect(() => {
        onChange(status);
    }, [status, onChange]);

    const changeStatus = (event) => {
        const value = event.target.value;

        setStatus(value);

        if (value === props.default.value) {
            dropdown.current.classList.remove('filled');
        } else {
            dropdown.current.classList.add('filled');
        }
    }

    const clearStatus = (event) => {
        event.stopPropagation();
        event.target.value = props.default.value;
        changeStatus(event);
        dropdown.current.classList.add('opened');
    }

    return (
        <div
            className="vertical-filters__dropdowns-list-item dropdown-filter-panel products opened"
            style={{borderTop: '1px solid #c5ccd3'}}
            ref={dropdown}
        >
            <div className="dropdown-filter-panel__header">
                <div className="dropdown-filter-panel__header-title">{props.title}</div>
                <div className="dropdown-filter-panel__header-icon"/>
                <div
                    className="dropdown-filter-panel__header-clear"
                    onClick={(event) => clearStatus(event)}
                >
                    {t('dashboard.orders_listing.filters.clear')}
                </div>
            </div>
            <div className="dropdown-filter-panel__body dropdown-filter-panel__body--noscroll">
                <div className="ecwid-orders-filter-popup">
                    <div className="options-list__search" aria-hidden="true" style={{display: 'none'}}/>
                    <ul className="options-list">
                        <li className="options-list__item">
                            <label className="options-list__label">
                                <input className="options-list__radio"
                                       type="radio"
                                       name={props.name}
                                       value={props.default.value}
                                       checked={status === props.default.value}
                                       onChange={(event) => changeStatus(event)}
                                />
                                <span className="radio-btn-icon"/>
                                <span className="options-list__label-text">{t('dashboard.orders_listing.filters.all_statuses')}</span>
                            </label>
                        </li>
                        {props.items?.map(item => (<li className="options-list__item" key={item.value}>
                                <label className="options-list__label">
                                    <input className="options-list__radio"
                                           type="radio"
                                           name={props.name}
                                           value={item.value}
                                           checked={status === item.value}
                                           onChange={(event) => changeStatus(event)}
                                    />
                                    <span className="radio-btn-icon"/>
                                    <span className="options-list__label-text">{item.label}</span>
                                </label>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    )
}