// eslint-disable-next-line
export default {
  ecwidInfo: '/profile',
  storeInfo: '/store',
  storeUpdate: '/store',
  saveChangedOrders: '/saveChangedOrders',
  ordersInfo: '/orders',
  orderBarcode: '/getBarcode',
  pdf: '/pdf'
  // ecwidProducts: '/products',
  // ecwidCategories: '/categories',
  // ecwidSetProductStatus: (id) => '/products/' + id,
  // ecwidSetProductsStatuses: '/products',

  // statistics: '/statistics'
}
