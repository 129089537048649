import React from 'react';
import "classnames";
import classNames from 'classnames';

export const Form = ({ children, props }) => {
    return (
        <div className="form-area">
            {children}
        </div>
    )
}

export const FormTitle = ({ children, props }) => {
    return (
        <div className="form-area__title form-area__title--medium">
            {children}
        </div>
    )
}

export const FormContent = ({ children, props }) => {
    return (
        <div className="form-area__content">
            {children}
        </div>
    )
}

export const Fieldsets = ({ children, props }) => {
    return (
        <div className="fieldsets-batch fieldsets-batch--horizontal">
            {children}
        </div>
    )
}

export const FieldsetInput = (props) => {
    return (
        <div className={"fieldset fieldset--no-label " + props?.statusClass ?? ''}>
            <div className="text-default muted">{props?.title}</div>
            <div className={"field field--medium " + classNames({ 'field--filled': props?.value })} disabled={props?.disabled}>
                <span className="fieldset__svg-icon"/>
                <label className="field__label">{props?.title}</label>
                <input
                    type={props.type ?? "text"}
                    className="field__input"
                    value={props.value ?? ''}
                    // defaultValue={props?.defaultValue}
                    name={props.name ?? ''}
                    onBlur={props?.onBlur}
                    onChange={props?.onChange}
                    disabled={props?.disabled}
                />
                <div className="field__placeholder">{props?.placeholder}</div>
                <span className="field-state--success">
                    <svg xmlns="http://www.w3.org/2000/svg" width="26px" height="26px" viewBox="0 0 26 26" focusable="false">
                        <path d="M5 12l5.02 4.9L21.15 4c.65-.66 1.71-.66 2.36 0 .65.67.65 1.74 0 2.4l-12.3 14.1c-.33.33-.76.5-1.18.5-.43 0-.86-.17-1.18-.5l-6.21-6.1c-.65-.66-.65-1.74 0-2.41.65-.65 1.71-.65 2.36.01z">
                        </path>
                    </svg>
                </span>
                <span className="field-state--close"
                      onClick={(event) => {
                          event.stopPropagation();
                          props?.attribute.reset();
                      }}
                      disabled>
                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 16 16" enableBackground="new 0 0 16 16" space="preserve" focusable="false">
                        <path d="M15.6,15.5c-0.53,0.53-1.38,0.53-1.91,0L8.05,9.87L2.31,15.6c-0.53,0.53-1.38,0.53-1.91,0c-0.53-0.53-0.53-1.38,0-1.9l5.65-5.64L0.4,2.4c-0.53-0.53-0.53-1.38,0-1.91c0.53-0.53,1.38-0.53,1.91,0l5.64,5.63l5.74-5.73c0.53-0.53,1.38-0.53,1.91,0c0.53,0.53,0.53,1.38,0,1.91L9.94,7.94l5.66,5.65C16.12,14.12,16.12,14.97,15.6,15.5z">
                        </path>
                    </svg>
                </span>
            </div>
            <div className="field__error" aria-hidden="true">{props.invalidMessage}</div>
            <p className="default-text muted">
                {props?.annotation}
            </p>
        </div>
    )
}

export const FieldsetInputPassword = (props) => {
    return (
        <div className={"fieldset fieldset--no-label " + props?.statusClass ?? ''}>
            <div className="text-default muted">{props?.title}</div>
            <div className={"field field--medium " + classNames({ 'field--filled': props?.value })} disabled={props?.disabled}>
                <span className="fieldset__svg-icon"/>
                <label className="field__label">{props?.title}</label>
                <input
                    type={props.type ?? "text"}
                    className="field__input"
                    value={props.value ?? ''}
                    // defaultValue={props?.defaultValue}
                    name={props.name ?? ''}
                    onBlur={props?.onBlur}
                    onChange={props?.onChange}
                    disabled={props?.disabled}
                />
                <div className="field__placeholder">{props?.placeholder}</div>
                <span className="field-state--success">
                    <svg xmlns="http://www.w3.org/2000/svg" width="26px" height="26px" viewBox="0 0 26 26" focusable="false">
                        <path d="M5 12l5.02 4.9L21.15 4c.65-.66 1.71-.66 2.36 0 .65.67.65 1.74 0 2.4l-12.3 14.1c-.33.33-.76.5-1.18.5-.43 0-.86-.17-1.18-.5l-6.21-6.1c-.65-.66-.65-1.74 0-2.41.65-.65 1.71-.65 2.36.01z">
                        </path>
                    </svg>
                </span>
                <span className="field-state--close"
                      onClick={(event) => {
                          event.stopPropagation();
                          props?.attribute.reset();
                      }}
                      disabled>
                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 16 16" enableBackground="new 0 0 16 16" space="preserve" focusable="false">
                        <path d="M15.6,15.5c-0.53,0.53-1.38,0.53-1.91,0L8.05,9.87L2.31,15.6c-0.53,0.53-1.38,0.53-1.91,0c-0.53-0.53-0.53-1.38,0-1.9l5.65-5.64L0.4,2.4c-0.53-0.53-0.53-1.38,0-1.91c0.53-0.53,1.38-0.53,1.91,0l5.64,5.63l5.74-5.73c0.53-0.53,1.38-0.53,1.91,0c0.53,0.53,0.53,1.38,0,1.91L9.94,7.94l5.66,5.65C16.12,14.12,16.12,14.97,15.6,15.5z">
                        </path>
                    </svg>
                </span>
            </div>
            <div className="fieldset__field-prefix">$</div>
		<div className="fieldset__field-suffix">kg</div>
		<div className="fieldset__field-action">
            <a href='/#'>Copy</a>
		</div>
            <div className="field__error" aria-hidden="true">{props.invalidMessage}</div>
            <p className="default-text muted">
                {props?.annotation}
            </p>
        </div>
    )
}

export const FieldsetFake = (props) => {
    return (
        <div className="fieldset fieldset--no-label ">{props?.text}</div>
    )
}
