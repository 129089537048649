import {React, useState, useEffect, useCallback, useMemo} from 'react';
import OrderCard from '../OrderCard/OrderCard';
import Pagination from '../Pagination/Pagination';
import NotFound from '../NotFoundBlock/NotFoundBlock';
import getOrdersInfo from '../../../utils/getOrdersInfo';
import { useInput } from '../../../hooks/useForm';
import { useStore } from '../../../store';
import './styles/filter-list.scss';
import { FilterInputsRange } from "./FilterInpursRange/FilterInputsRange";
import { FilterAttributeList } from "./FilterAttributeList/FilterAttributeList";
import { debounce } from "throttle-debounce";
import { FilterDateRange } from "./FilterDateRange/FilterDateRange";
import OrderCardLoading from '../OrderCard/OrderCardLoading';
import Storage from "../../../utils/Storage";
import { OrdersSync } from "./OrdersSync/OrdersSync";
import { t } from 'i18next';
import classNames from "classnames";
import moment from "moment";

export const FilteredList = () => {
    const [ordersList, setOrdersList] = useState();
    //TODO: Переписать.
    const currentPage = useInput(1, 1, { isEmpty: true, isInteger: true })
    const [cp, setCp] = useState(currentPage.value);
    const store = useStore();
    const [loading, setLoading] = useState(true);
    //filters
    const [orderId, setOrderId] = useState('');
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [paymentStatus, setPaymentStatus] = useState('ALL');
    const [fulfillmentStatus, setFulfillmentStatus] = useState('ALL');
    const [brtStatus, setBrtStatus] = useState('ALL');
    const [minPrice, setMinPrice] = useState('');
    const [maxPrice, setMaxPrice] = useState('');
    //States for multiselect
    const [selectedCount, setSelectedCount] = useState(0);
    // eslint-disable-next-line no-unused-vars
    //const [visibleMassUpdates, setVisibleMassUpdates] = useState();
    const [orderSync, setOrderSync] = useState(null);
    const [ordersCheckedList, setOrdersCheckedList] = useState([]);
    // ChangedData
    const [changedData, setChangedData] = useState({});
    const [countChangedOrders, setCountChangedOrders] = useState(0);
    const [statusesEvent, setStatusesEvent] = useState(false);

    const [ordersStatuses, setOrdersStatuses] = useState([]);
    //States for downloading report
    const [visibleDownloadReport, setVisibleDownloadReport] = useState();
    const [isReportLoading, setReportLoading] = useState();
    const {
        ecwidStore: {getPdfReport},
    } = useStore();
    // eslint-disable-next-line no-unused-vars
    const setAllChecked = () => {
        ordersList?.items?.forEach((order) => {
            setOrdersCheckedList((o) => {
                if (!o?.find(f => f.id === order.id) && order.brt_creation_status === "SHIPMENT_SHIPPED") {
                    return [...o, order];
                } else {
                    return o;
                }
            });
        });
    }

    const downloadReport = () => {
        setReportLoading(true);
        let orders = ordersCheckedList.map((order) => order.id);
        getPdfReport(orders).then((response) => {
            const file = window.URL.createObjectURL(response);
            const a = document.createElement("a");
            a.href = file;
            a.download = "distinta_"  + moment(new Date()).format('YYYYMMDDHHmm') + ".pdf";
            document.body.appendChild(a);
            a.click();
        }).finally(() => {
            setReportLoading(false);
            setOrdersCheckedList([]);
        });
    }

    // eslint-disable-next-line no-unused-vars
    const unsetAllChecked = () => {
        setOrdersCheckedList([]);
    }

    useEffect(() => {
        setCp(1);
        currentPage.setValue(1);
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [orderId, startDate, endDate, paymentStatus, fulfillmentStatus, brtStatus, minPrice, maxPrice]);

    useEffect(() => {
        setSelectedCount(ordersCheckedList?.length);
    }, [ordersCheckedList]);

    useEffect(() => {
        setLoading(true);
        getOrdersInfo(
            store.api,
            orderId,
            startDate,
            endDate,
            paymentStatus,
            fulfillmentStatus,
            brtStatus,
            minPrice,
            maxPrice,
            cp
        ).then((orders) => {
            setOrdersList(orders);
            ordersStatusesSetter(orders);
            setLoading(false);
        });
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [cp, orderId, startDate, endDate, paymentStatus, fulfillmentStatus, brtStatus, minPrice, maxPrice, statusesEvent]);

    const ordersStatusesSetter = (orders) => {
        orders?.items?.forEach((order) => {
            setOrdersStatuses((ordersStatuses) => {
                ordersStatuses = ordersStatuses?.filter(os => os.id !== order.id);
                if ((orders?.order_brt_operations_jobs?.find(os => os?.internal_order_id === order.id) &&
                        order?.brt_last_creation_request_code === null) ||
                    (orders?.order_brt_operations_jobs?.find(os => os?.internal_order_id === order.id)?.type === 'brt_loading_status' &&
                        order.brt_last_creation_request_code !== -1031)
                ) {
                    return [...ordersStatuses, {
                        'id': order?.id,
                        'status': 'inProgress',
                        'title': t('dashboard.orders_listing.order_card.bubbles.processing.processing'),
                        'bubbleTitle': t('dashboard.orders_listing.order_card.bubbles.processing.processing'),
                        'bubbleMessage': t('dashboard.orders_listing.order_card.bubbles.processing.the_order_is_being_processed')
                    }];
                } else if (order.brt_last_creation_request_code === -1031) {
                    return [...ordersStatuses, {
                        'id': order?.id,
                        'status': 'inProgress',
                        'title': t('dashboard.orders_listing.order_card.bubbles.long_processing.processing'),
                        'bubbleTitle': t('dashboard.orders_listing.order_card.bubbles.processing.processing'),
                        'bubbleMessage': t('dashboard.orders_listing.order_card.bubbles.long_processing.the_order_is_being_processed')
                    }];
                } else if (order?.brt_last_creation_request_code !== null &&
                    order?.brt_last_creation_request_code >= 0 &&
                    order.brt_creation_status !== "SHIPMENT_SHIPPED"
                ) {
                    return [...ordersStatuses, {
                        'id': order?.id,
                        'status': 'successfully',
                        'title': order?.brt_creation_status_updated_at
                    }];
                } else if (order?.brt_last_creation_request_code !== null && order?.brt_last_creation_request_code < 0) {
                    return [...ordersStatuses, {
                        'id': order?.id,
                        'status': 'unsuccessfully',
                        'bubbleTitle': 'Error code: ' + order?.brt_last_creation_request_code,
                        'bubbleMessage': order?.brt_last_creation_request_message,
                    }];
                } else if (order?.brt_creation_status === 'SHIPMENT_SHIPPED' && order?.brt_last_tracking_status_date === null) {
                    return [...ordersStatuses, {
                        'id': order?.id,
                        'status': 'tracking',
                        'bubbleTitle': t('order_statuses.prefixes.tracking_information'),
                        'bubbleMessage': t('order_statuses.tracking'),
                    }];
                } else if (order?.brt_creation_status === 'SHIPMENT_SHIPPED' && order?.brt_last_tracking_status_date !== null) {
                    return [...ordersStatuses, {
                        'id': order?.id,
                        'status': 'trackingLine',
                        'bubbleTitle': t('order_statuses.prefixes.tracking_information'),
                        'bubbleMessage': 'Last tracking: ' + order?.brt_last_tracking_status_date,
                    }];
                } else if (order?.package_width === null ||
                    order?.package_height === null ||
                    order?.package_length === null ||
                    order?.package_weight === null) {
                    return [...ordersStatuses, {
                        'id': order?.id,
                        'status': 'info',
                        'bubbleTitle': '',
                        'bubbleMessage': t('order_statuses.immutable'),
                    }];
                }
                return ordersStatuses;
            });
        });
    }

    useEffect(() => {
        setVisibleDownloadReport(selectedCount === 0);
    }, [selectedCount]);

    // eslint-disable-next-line no-unused-vars
    const orderCardsSelectHandler = () => {
        setVisibleDownloadReport(true);
    }

    const [startedSync, setStartedSync] = useState();

    useEffect(() => {
        if (process.env.REACT_APP_BASE_URL_BROADCASTING_ENABLE === 'true') {
            window.Echo.channel('order.sync.' + Storage.get('store_id'))
                .listen('OrderSyncStaredEvent', (data) => {
                    setStartedSync(data.status);
                })
                .listen('OrderSyncEvent', (data) => {
                    if (data.progress_now !== data.progress_max) {
                        setOrderSync(data);
                    } else {
                        setOrderSync(null);
                        setLoading(true);
                        getOrdersInfo(
                            store.api,
                            orderId,
                            startDate,
                            endDate,
                            paymentStatus,
                            fulfillmentStatus,
                            brtStatus,
                            minPrice,
                            maxPrice,
                            cp
                        ).then((orders) => {
                            setOrdersList(orders);
                            ordersStatusesSetter(orders);
                            setLoading(false);
                        });
                    }
                })
                .listen('OrdersStatusesEvent', (data) => {
                    setStatusesEvent(!statusesEvent);
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cp, orderId, startDate, endDate, paymentStatus, fulfillmentStatus, brtStatus, minPrice, maxPrice, store.api, ordersList]);

    const paymentsStatusFilterList = [
        { label: t('dashboard.orders_listing.filters.payment_statuses.AWAITING_PAYMENT'), value: 'AWAITING_PAYMENT' },
        { label: t('dashboard.orders_listing.filters.payment_statuses.PAID'), value: 'PAID' },
        { label: t('dashboard.orders_listing.filters.payment_statuses.CANCELLED'), value: 'CANCELLED' },
        { label: t('dashboard.orders_listing.filters.payment_statuses.REFUNDED'), value: 'REFUNDED' },
        { label: t('dashboard.orders_listing.filters.payment_statuses.PARTIALLY_REFUNDED'), value: 'PARTIALLY_REFUNDED' }
    ];

    const fulfillmentStatusFilterList = [
        { label: t('dashboard.orders_listing.filters.fulfillment_statuses.AWAITING_PROCESSING'), value: 'AWAITING_PROCESSING' },
        { label: t('dashboard.orders_listing.filters.fulfillment_statuses.PROCESSING'), value: 'PROCESSING' },
        { label: t('dashboard.orders_listing.filters.fulfillment_statuses.SHIPPED'), value: 'SHIPPED' },
        { label: t('dashboard.orders_listing.filters.fulfillment_statuses.DELIVERED'), value: 'DELIVERED' },
        { label: t('dashboard.orders_listing.filters.fulfillment_statuses.WILL_NOT_DELIVER'), value: 'WILL_NOT_DELIVER' },
        { label: t('dashboard.orders_listing.filters.fulfillment_statuses.RETURNED'), value: 'RETURNED' },
        { label: t('dashboard.orders_listing.filters.fulfillment_statuses.READY_FOR_PICKUP'), value: 'READY_FOR_PICKUP' },
        { label: t('dashboard.orders_listing.filters.fulfillment_statuses.OUT_FOR_DELIVERY'), value: 'OUT_FOR_DELIVERY' },
    ];

    const brtStatusFilterList = [
        { label: t('dashboard.orders_listing.filters.brt_statuses.AWAITING_CREATION'), value: 'AWAITING_CREATION' },
        { label: t('dashboard.orders_listing.filters.brt_statuses.SHIPMENT_CREATED'), value: 'SHIPMENT_CREATED' },
        { label: t('dashboard.orders_listing.filters.brt_statuses.SHIPMENT_CONFIRMED'), value: 'SHIPMENT_CONFIRMED' },
        { label: t('dashboard.orders_listing.filters.brt_statuses.AWAITING_TRACKING_STATUS'), value: 'AWAITING_TRACKING_STATUS' },
        { label: t('dashboard.orders_listing.filters.brt_statuses.DATI'), value: 'DATI SPEDIZ. TRASMESSI A BRT' },
        { label: t('dashboard.orders_listing.filters.brt_statuses.RITIRATA'), value: 'RITIRATA' },
        { label: t('dashboard.orders_listing.filters.brt_statuses.PARTITA'), value: 'PARTITA' },
        { label: t('dashboard.orders_listing.filters.brt_statuses.ARRIVATA_IN_FILIALE'), value: 'ARRIVATA IN FILIALE' },
        { label: t('dashboard.orders_listing.filters.brt_statuses.IN_CONSEGNA'), value: 'IN CONSEGNA' },
        { label: t('dashboard.orders_listing.filters.brt_statuses.CONSEGNATA'), value: 'CONSEGNATA' },
    ];

    const searchOrder = debounce(1000, (value) => {
        setOrderId(value);
    });

    const setPaginationPage = debounce(1000, (value) => {
        setCp(value);
    });

    const onChangeAttributes = useCallback((changed, orderId) => {
        if (changed) {
            const countChangedOrder = Object.keys(changed).length;

            if (countChangedOrder > 0) {
                setChangedData(prev => {
                    return {
                        ...prev,
                        [orderId]: changed
                    }
                });
            } else {
                setChangedData(prev => {
                    delete prev[orderId];

                    return {
                        ...prev
                    }
                });
            }
        }
    }, []);

    useEffect(() => {
        onChangeAttributes();
        setCountChangedOrders(Object.keys(changedData).length);
    }, [changedData, onChangeAttributes]);

    const saveChangedOrders = () => {
        setLoading(true);
        store.ecwidStore.saveChangedOrders(changedData).then(response => {
            getOrdersInfo(
                store.api,
                orderId,
                startDate,
                endDate,
                paymentStatus,
                fulfillmentStatus,
                brtStatus,
                minPrice,
                maxPrice,
                cp
            ).then((orders) => {
                setOrdersList(orders);
                ordersStatusesSetter(orders);
                setChangedData({});
                setLoading(false);
            });
        });
    };

    const cancelChangedOrders = () => {
        setLoading(true);
        setChangedData({});
        getOrdersInfo(
            store.api,
            orderId,
            startDate,
            endDate,
            paymentStatus,
            fulfillmentStatus,
            brtStatus,
            minPrice,
            maxPrice,
            cp
        ).then((orders) => {
            setOrdersList(orders);
            ordersStatusesSetter(orders);
            setLoading(false);
        });
    };

    const isSelectAllDisabled = useMemo(()=>{
        return (ordersList?.items.find((o)=>o.brt_creation_status === "SHIPMENT_SHIPPED"));
    },[ordersList]);

    return (
        <div className="filtered-list">
            <div className="filtered-list__header">
                <div className="filter-panel">
                    <div className="filter-panel-toggle dropdown-filter-panel no-body btn-default">
                        <div className="dropdown-filter-panel__header">
                            <div className="dropdown-filter-panel__header-title">{t('dashboard.orders_listing.filter')}</div>
                            <div className="dropdown-filter-panel__header-icon"/>
                        </div>
                    </div>
                    <div className="filter-panel__search">
                        <div className="fieldset fieldset--icon fieldset--with-label">
                            <div>
                                <div className="fieldset__field-wrapper fieldset--no-label">
                                    <div className="field field--medium">
                                        <span className="fieldset__svg-icon">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="#9C9C9C" viewBox="0 0 16 16"
                                                 focusable="false">
                                                <path
                                                    d="M5.8 3.1c1-.9 2.1-1.5 3.6-1.5 1.4 0 2.6.5 3.6 1.5.9 1 1.5 2.1 1.5 3.6 0 1.4-.5 2.6-1.5 3.6-1 .9-2.1 1.5-3.6 1.5-1.3 0-2.6-.5-3.5-1.5-1-1-1.5-2.1-1.5-3.6-.1-1.4.3-2.5 1.4-3.6M.8 16c.2 0 .4-.1.6-.2l3.8-3.7.3.2c1 .7 2.3 1.2 3.8 1.2.9 0 1.8-.2 2.6-.5.8-.4 1.5-.8 2.2-1.5.6-.6 1.1-1.3 1.5-2.1.3-.9.4-1.7.4-2.6 0-.9-.2-1.8-.5-2.6-.4-.8-.8-1.5-1.5-2.2-.6-.6-1.3-1.1-2.1-1.5C11 .2 10.2 0 9.3 0c-.9 0-1.8.2-2.6.5-.8.4-1.5.8-2.2 1.5-.6.6-1.1 1.3-1.4 2.1-.4.9-.5 1.7-.5 2.5 0 1.4.4 2.6 1.2 3.8l.2.3-3.7 3.7c-.2.2-.3.4-.3.7 0 .2.1.4.2.6.3.3.5.3.6.3">
                                                </path>
                                            </svg>
                                        </span>
                                        <label className="field__label">{t('dashboard.orders_listing.filters.search_input_placeholder')}</label>
                                        <input type="text"
                                               className="field__input"
                                               onChange={(event) => searchOrder(event.target.value)}
                                        />
                                        <div className="field__placeholder"
                                             style={{zIndex: '1' }}>{t('dashboard.orders_listing.filters.search_input_placeholder')}</div>
                                        <span className="field-state--success">
                                            {/* <svg xmlns="http://www.w3.org/2000/svg" width="26px" height="26px" viewBox="0 0 26 26" focusable="false"><path d="M5 12l5.02 4.9L21.15 4c.65-.66 1.71-.66 2.36 0 .65.67.65 1.74 0 2.4l-12.3 14.1c-.33.33-.76.5-1.18.5-.43 0-.86-.17-1.18-.5l-6.21-6.1c-.65-.66-.65-1.74 0-2.41.65-.65 1.71-.65 2.36.01z"></path></svg></span> <span class ="field-state--close"><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns: xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 16 16" enable-background="new 0 0 16 16" xml: space="preserve" focusable="false"><path d="M15.6,15.5c-0.53,0.53-1.38,0.53-1.91,0L8.05,9.87L2.31,15.6c-0.53,0.53-1.38,0.53-1.91,0c-0.53-0.53-0.53-1.38,0-1.9l5.65-5.64L0.4,2.4c-0.53-0.53-0.53-1.38,0-1.91c0.53-0.53,1.38-0.53,1.91,0l5.64,5.63l5.74-5.73 c0.53-0.53,1.38-0.53,1.91,0c0.53,0.53,0.53,1.38,0,1.91L9.94,7.94l5.66,5.65C16.12,14.12,16.12,14.97,15.6,15.5z"></path></svg> */}
                                        </span>
                                    </div>
                                    <div className="fieldset__field-prefix" />
                                    <div className="fieldset__field-suffix" />
                                </div>
                                <div className="field__error" aria-hidden="true" style={{ display: "none" }} />
                            </div>
                            <div className="fieldset__note" aria-hidden="true" style={{ display: "none" }} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="filtered-list__body">
                <div className="vertical-filters__sticky-panel">
                    <button type="button" className="btn btn-success btn-medium">Show Products</button>
                </div>
                <div className="ecwid-orders__filter-panel vertical-filters">
                    <FilterDateRange
                        title={t('dashboard.orders_listing.filters.date')}
                        onChangeStartDate={(date) => setStartDate(date)}
                        onChangeEndDate={(date) => setEndDate(date)}
                    />
                    <FilterInputsRange
                        title={t('dashboard.orders_listing.filters.price')}
                        onChange={debounce(500, (start, end) => {
                            setMinPrice(start);
                            setMaxPrice(end);
                        })}
                    />
                    <FilterAttributeList
                        title={t('dashboard.orders_listing.filters.payment_status')}
                        name={'paymentStatus'}
                        default={{
                            label: t('dashboard.orders_listing.filters.all_statuses'),
                            value: 'ALL'
                        }}
                        items={paymentsStatusFilterList}
                        onChange={setPaymentStatus}
                    />
                    <FilterAttributeList
                        title={t('dashboard.orders_listing.filters.fulfillment_status')}
                        name={'fulfillmentStatus'}
                        default={{
                            label: t('dashboard.orders_listing.filters.all_statuses'),
                            value: 'ALL'
                        }}
                        items={fulfillmentStatusFilterList}
                        onChange={setFulfillmentStatus}
                    />
                    <FilterAttributeList
                        title={t('dashboard.orders_listing.filters.brt_status')}
                        name={'brtStatus'}
                        default={{
                            label: t('dashboard.orders_listing.filters.all_statuses'),
                            value: 'ALL'
                        }}
                        items={brtStatusFilterList}
                        onChange={setBrtStatus}
                    />
                </div>
                <div className="filtered-list__items">
                    {(orderSync || startedSync) && <OrdersSync
                        statusStart={startedSync}
                        total={orderSync?.progress_max}
                        current={orderSync?.progress_now}
                    />}
                    {loading ?
                        <div>
                            <OrderCardLoading />
                            <OrderCardLoading />
                            <OrderCardLoading />
                            <OrderCardLoading />
                            <OrderCardLoading />
                            <div
                                className="pagination"
                                style={{
                                    display: 'flex',
                                    marginTop: '14px',
                                    justifyContent: 'center'
                                }}>
                                <div className="placeholder-blink placeholder-btn placeholder-blink--delay3x"
                                     style={{minWidth: '300px', height: '44px', marginRight: '4px' }}/>
                            </div>
                        </div> :
                        <div>
                            <div className="d-flex flex-wrap justify-content-between align-items-center"
                                 style={{ marginBottom: '12px' }}>
                                <div className="d-flex flex-wrap wrapper-menu">
                                    <div className="selected-products">
                                        <span style={{ margin: '0 6px' }}>
                                            <span className="filter-status-counter">
                                                {t('dashboard.orders_listing.order_selected')} {selectedCount}
                                            </span>
                                        </span>
                                    </div>
                                    {isSelectAllDisabled ?
                                        <div
                                            className="align-self-center filter-status--handler cursor-pointer"
                                            onClick={() => {setAllChecked();}}
                                        >
                                            {t('dashboard.orders_listing.select_all_products_on_page')}
                                        </div>
                                        :
                                        <div
                                            className="align-self-center filter-status--handler"
                                             style={{color:'#d4d4d4'}}>
                                            {t('dashboard.orders_listing.select_all_products_on_page')}
                                        </div>
                                    }
                                    <div
                                        className={"align-self-center filter-status--handler cursor-pointer " + classNames({ 'd-none': visibleDownloadReport })}
                                        onClick={(e) => {
                                            unsetAllChecked();
                                            orderCardsSelectHandler();
                                        }}
                                    >
                                        {t('dashboard.orders_listing.undo_all_changes')}
                                    </div>
                                </div>
                                {visibleDownloadReport &&
                                    <div className="text-small muted ml-auto" style={{marginRight: '8px'}}>
                                        {t('dashboard.orders_listing.download_report_hint')}
                                    </div>
                                }
                                <button
                                    type="button"
                                    className={classNames("btn btn-default btn-small", {
                                        'btn-loading': isReportLoading
                                    })}
                                    disabled={visibleDownloadReport}
                                    aria-hidden="true"
                                    onClick={() => downloadReport()}
                                >
                                    {t('dashboard.orders_listing.download_report')}
                                </button>
                                {/*<div
                                    className={"btn-group btn-group--filter dropdown-toggle mb-0 " + classNames({ 'd-none': visibleDownloadReport })}
                                    onClick={(e) => {
                                        e.target.closest('.dropdown-toggle').classList.toggle('opened');
                                    }}
                                >
                                    <button
                                        type="button"
                                        className="btn btn-default btn-small"
                                        aria-hidden="true"
                                        style={{ display: 'none' }}
                                    />
                                    <div className="btn btn-default btn-dropdown list-dropdown-no-general-text btn-small mr-0">
                                        <div className="iconable-link" role="link" tabIndex="0">
                                            <span className="iconable-link__text">
                                                <span className="">{t('dashboard.orders_listing.mass_update')}</span>
                                            </span>
                                            <span className="iconable-link__icon">
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 28" focusable="false">
                                                    <path d="M3.3 9.5l5.6 5.1 6-5.1c.8-.7 1.9-.7 2.6 0 .8.7.8 1.8 0 2.5l-7.2 6.4c-.5.4-1 .6-1.4.6s-1-.2-1.3-.5L.7 12.1c-.8-.7-.8-1.8 0-2.5.6-.8 1.9-.8 2.6-.1z" />
                                                </svg>
                                            </span>
                                        </div>
                                    </div>
                                    <div className="list-dropdown list-dropdown-medium list-dropdown--left list-dropdown--left--border-none">
                                        <div className="list-dropdown__content">
                                            <ul className="list-dropdown__item--custom">
                                                <li
                                                    className="list-dropdown__item"
                                                    onClick={() => {

                                                    }}
                                                >
                                                    {t('dashboard.orders_listing.send_selected')}
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>*/}
                            </div>
                            {countChangedOrders > 0 && <div
                                className="d-flex align-items-center justify-content-between flex-wrap changed-order">
                                <div className="text-default">
                                    {t('changedOrderPrefix')} {Object.keys(changedData).length} {t('changedOrderPostfix')}
                                </div>
                                <div>
                                    <button className="btn btn-default btn-medium mr-3"
                                            onClick={cancelChangedOrders}>{t('dashboard.changed_forms.cancel')}</button>
                                    <button className="btn btn-primary btn-medium"
                                            onClick={saveChangedOrders}>{t('dashboard.changed_forms.save')}</button>
                                </div>
                            </div>}
                            {ordersList?.totalPages !== 0 ?
                                ordersList?.items?.map((order) => {
                                    return (
                                        <OrderCard
                                            key={order.order_id}
                                            id={order.id}
                                            stream={order.stream}
                                            changedData={changedData[order.id]}
                                            ecwidOrderId={order.order_id}
                                            orderDate={order.order_date}
                                            weight={order.package_weight}
                                            length={order.package_length}
                                            width={order.package_width}
                                            height={order.package_height}
                                            currentStatus={order.brt_creation_status}
                                            brtTrackingStatus={order.brt_tracking_status}
                                            onChangeCheckbox={() => {
                                                setOrdersCheckedList((o) => {
                                                    if (o?.find(f => f.id === order.id)) {
                                                        return o.filter(f => f.id !== order.id);
                                                    } else {
                                                        return [...o, order];
                                                    }
                                                });
                                            }}
                                            onChangeAttributes={onChangeAttributes}
                                            productCount={order.product_count}
                                            checked={ordersCheckedList?.find(o => o.id === order.id) ?? false}
                                            bubbleConfig={ordersStatuses?.find(os => os.id === order.id)}
                                            shippingMethod={order?.shipping_method}
                                            totalPrice={order?.total_price}
                                            shippingRate={order?.shipping_rate}
                                            trackingEvents={order?.tracking_events}
                                        />
                                    );
                                }) : <NotFound>
                                    <p>{t('dashboard.orders_listing.not_found_orders_message')}</p>
                                </NotFound>
                            }
                            {ordersList?.totalPages === 0 ? '' :
                                <Pagination
                                    page={currentPage.value}
                                    nextClick={() => {
                                        currentPage.setValue(ordersList?.nextPage);
                                        setCp(ordersList?.nextPage);
                                    }}
                                    prevClick={() => {
                                        currentPage.setValue(ordersList?.prevPage);
                                        setCp(ordersList?.prevPage);
                                    }}
                                    onChange={(e) => {
                                        if (e.target.value !== '' && e.target.value <= ordersList?.totalPages && e.target.value > 0) {
                                            setPaginationPage(e.target.value);
                                        }
                                        currentPage.setValue(e.target.value);
                                    }}
                                    total={ordersList?.totalPages}
                                    prevClickDisabled={ordersList?.prevPage === currentPage?.value}
                                    nextClickDisabled={ordersList?.nextPage === currentPage?.value}
                                />}
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}
