import './i18n';
import './index.css';
import 'animate.css';
import App from './App';
import ReactDOM from 'react-dom';
import { Router } from "react-router";
import history from "./utils/history";
import { createStore } from "./store";
import * as Sentry from "@sentry/react";
import reportWebVitals from './reportWebVitals';
import React, { Suspense, StrictMode } from 'react';

Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN
});

(async () => {
    const StoreProvider = await createStore();

    ReactDOM.render(
        <Suspense fallback={<div>Loading...</div>}>
            <StoreProvider>
                <StrictMode>
                    <Router history={history}>
                        <App />
                    </Router>
                </StrictMode>
            </StoreProvider>
        </Suspense>,
        document.getElementById('root')
    );
})();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
