import apiList from "../../constants/apiList";

// eslint-disable-next-line
export default class {
    constructor(axios) {
        this.axios = axios;
    };

    async getEcwidInfo() {
        try {
            const response = await this.axios({
                method: 'get',
                url: apiList.ecwidInfo,
            })
            return response.data;
        } catch (error) {
            throw error;
        }
    }

    async getStoreInfo() {
        try {
            const response = await this.axios({
                method: 'get',
                url: apiList.storeInfo,
            })
            return response.data.data;
        } catch (error) {
            throw error;
        }
    }

    async updateStore({ data }) {
        try {
            const response = await this.axios({
                method: 'post',
                url: apiList.storeUpdate,
                data: {
                    'data': data
                }
            })
            return response.data.data;
        } catch (error) {
            throw error;
        }
    }

    async saveChangedOrders(changedData) {
        try {
            const response = await this.axios({
                method: 'post',
                url: apiList.saveChangedOrders,
                data: {
                    changedData: changedData,
                }
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    }
}