import React from 'react'

const OrderCardLoading = () => {
    return (
        <div className="list-element list-element--compact list-element--has-hover list-element--inline-mode">
            <div className="list-element__toggle">
                <input type="checkbox" defaultValue="on" id="" tabIndex="0" className="list-element__toggle-checkbox" />
                <label htmlFor="" className="list-element__toggle-label"/>
            </div>
            <div className="list-element__content">
                <div className="list-element__info">
                    <div className="placeholder-titles placeholder-titles--small">
                        <div className="placeholder-blink placeholder-title placeholder-blink--delay2x"/>
                        <div className="placeholder-subtitle">
                            <div className="placeholder-blink placeholder-subtitle__line placeholder-blink--delay2x"/>
                            <div className="placeholder-blink placeholder-subtitle__line placeholder-blink--delay2x"/>
                            <div className="placeholder-blink placeholder-subtitle__line placeholder-blink--delay2x"/>
                            <div className="placeholder-blink placeholder-subtitle__line placeholder-blink--delay2x"/>
                            <div className="placeholder-blink placeholder-subtitle__line placeholder-blink--delay2x"/>
                        </div>
                    </div>
                    <div className="list-element__data-row" style={{
                        display: 'flex',
                        flexDirection: 'row',
                        paddingTop: '4px'
                    }}>
                        <div className="placeholder-blink placeholder-btn placeholder-blink--delay3x" style={{ width: '100%', marginRight: '4px' }}/>
                        <div className="placeholder-blink placeholder-btn placeholder-blink--delay3x" style={{ width: '100%', marginRight: '4px' }}/>
                        <div className="placeholder-blink placeholder-btn placeholder-blink--delay3x" style={{ width: '100%', marginRight: '4px' }}/>
                        <div className="placeholder-blink placeholder-btn placeholder-blink--delay3x" style={{ width: '100%', marginRight: '4px' }}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OrderCardLoading;